import { useEffect } from "preact/hooks";
import { injectAboutReadMoreButton } from "../utils/injectAboutReadMoreButton";
import { ConsentDisplaySkeletonNo } from "./ConsentDisplaySkeletonNo";
import { ConsentDisplaySkeletonSv } from "./ConsentDisplaySkeletonSv";
import { logUnhandledSwitch } from "../utils/unhandledSwitch";
import type { LocalizationConfigResponse } from "@again-enable-banking/api/localization/load";

type Props = {
  onCancel: () => any;
  localization: LocalizationConfigResponse;
};

export const ConsentDisplaySkeleton = (props: Props) => {
  useEffect(() => {
    injectAboutReadMoreButton(props);
  }, []);
  switch (props.localization.consentLocale) {
    case "NO":
      return <ConsentDisplaySkeletonNo {...props} />;
    case "SV":
      return <ConsentDisplaySkeletonSv {...props} />;
    default:
      logUnhandledSwitch(props.localization.consentLocale);
      return <ConsentDisplaySkeletonSv {...props} />;
  }
};
