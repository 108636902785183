<template>
  <div class="enablebanking-aspsp-list">
    <Spinner v-if="isLoading" />
    <div v-else class="card-list-container">
      <div class="card-list">
        <Card
          v-for="(card, index) in filteredCards"
          :key="index"
          :image-url="`${card.logo}${logoTransform}`"
          :title="card.name"
          :beta="card.beta"
          :search-term="searchTerm"
          @card-select="handleCardClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { i18n } from '../i18n.js'
import config from '../config'
import Card from '../components/elements/Card.vue'
import Spinner from '../components/elements/Spinner.vue'

export default {
  name: 'AspspList',
  components: {
    Card,
    Spinner
  },
  i18n,
  data () {
    return {
      observer: null,
      aspsps: [],
      isLoading: false,
      logoTransform: '',
      searchTerm: '',
      brokerOrigin: '',
      sandbox: false,
      noBeta: false,
      country: '',
      psuType: 'personal',
      service: 'AIS'
    }
  },
  async mounted () {
    this.initObserver()
    this.updateAttributes()
    await this.fetchAspsps()
  },
  unmounted () {
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    updateAttributes () {
      const parent = this.$el?.parentElement
      if (!parent) return // Exit if parentElement is null or undefined

      this.sandbox = parent.hasAttribute('sandbox') && parent.getAttribute('sandbox') !== 'false' // Default to false
      this.brokerOrigin =
        parent.getAttribute('origin') ??
        (this.sandbox ? config.publicSandboxOrigin : config.publicOrigin)
      this.searchTerm = parent.getAttribute('search-term') ?? ''
      this.country = parent.getAttribute('country') ?? ''
      this.psuType = parent.getAttribute('psu-type') ?? 'personal'
      this.service = parent.getAttribute('service') ?? 'AIS'
      this.logoTransform = parent.getAttribute('logo-transform') ?? ''
      this.noBeta = parent.hasAttribute('no-beta') && parent.getAttribute('no-beta') !== 'false' // Default to false
    },
    initObserver () {
      const parent = this.$el?.parentElement
      if (!parent) return

      const callback = () => {
        this.$nextTick(() => {
          this.updateAttributes()
        })
      }

      this.observer = new MutationObserver(callback)
      this.observer.observe(parent, { attributes: true })
    },
    async fetchAspsps () {
      this.isLoading = true
      try {
        const response = await fetch(
          `${this.brokerOrigin}/api/aspsps?sandbox=${encodeURIComponent(this.sandbox)}&country=${encodeURIComponent(
            this.country
          )}&psu_type=${encodeURIComponent(this.psuType)}&service=${encodeURIComponent(this.service)}`,
          {
            method: 'GET',
            mode: 'cors'
          }
        )

        const data = await response.json()
        this.aspsps = data.aspsps

        if (this.$el?.parentElement) {
          this.$el.parentElement.dispatchEvent(new CustomEvent('ready'))
        }
      } catch (error) {
        if (this.$el?.parentElement) {
          this.$el.parentElement.dispatchEvent(
            new CustomEvent('error', { detail: { error } })
          )
        }
      } finally {
        this.isLoading = false
      }
    },
    handleCardClick (e) {
      if (this.$el?.parentElement) {
        this.$el.parentElement.dispatchEvent(
          new CustomEvent('selected', {
            detail: {
              country: this.country,
              name: e.title,
              beta: e.beta,
              psuType: this.psuType,
              sandbox: this.sandbox,
              service: this.service
            }
          })
        )
      }
    },
    filteredCardsFunc (term) {
      this.filteredCards = this.aspsps
        .filter((card) => card.name.toLowerCase().includes(term.toLowerCase()) &&
        (!(this.noBeta && card.beta)))
        .sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  computed: {
    filteredCards () {
      return this.aspsps
        .filter(
          (card) =>
            card.name.toLowerCase().includes(this.searchTerm.toLowerCase()) &&
            (!(this.noBeta && card.beta))
        )
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    combinedAttributes () {
      return `${this.brokerOrigin}-${this.sandbox}-${this.country}-${this.psuType}-${this.service}`
    }
  },
  watch: {
    combinedAttributes: {
      async handler () {
        await this.fetchAspsps()
      }
    },
    searchTerm: {
      handler (val) {
        this.filteredCardsFunc(val)
      }
    }
  }
}
</script>

<style scoped>
.card-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  gap: 0.5rem;
}
</style>
