import type { ApiStartUserAuthorisationSuccessfulResponse } from "@again-enable-banking/api/index";
import type { LocalizationConfigResponse } from "@again-enable-banking/api/localization/load";

export function generateConsentHtml(
  response: ApiStartUserAuthorisationSuccessfulResponse,
  localization: LocalizationConfigResponse,
  elemId: string
) {
  return [
    `<enablebanking-consent`,
    `id="${elemId}"`,
    `authorization="${response.authorization_id}"`,
    `locale="${localization.consentLocale}"`,
    `can-cancel`,
    `no-redirect`,
    response.is_sandbox ? "sandbox" : "",
    `></enablebanking-consent>`,
  ].join(" ");
}
