export default{
  // General
  'About {appName}': 'Par {appName}',
  'About Enable Banking API': 'Par Enable Banking API',
  'Application description is not provided.': 'Lietojumprogrammas apraksts nav sniegts.',
  'Authenticate': 'Autentificēt',
  'Authentication method': 'Autentifikācijas metode',
  'Authentication session has expired': 'Autentifikācijas sesija ir beigusies',
  'Cancel': 'Atcelt',
  'Click {0} button if you have confirmed authorization in the BankID app.': 'Noklikšķiniet uz pogas {0}, ja esat apstiprinājis autorizāciju lietotnē BankID.',
  'Continue authentication': 'Turpināt autentifikāciju',
  'Enter your OTP code with index {0}': 'Ievadiet savu OTP kodu ar indeksu {0}',
  'Error during authentication:': 'Kļūda autentifikācijas laikā:',
  'Error while updating authentication status': 'Atjauninot autentifikācijas statusu, radās kļūda',
  'Open the BankID app on this device': 'Šajā ierīcē atveriet lietotni BankID',
  'OTP code': 'OTP kods',
  'Redirecting to your account servicing provider': 'Notiek novirzīšana uz jūsu konta apkalpošanas nodrošinātāju',
  'Returning to the authentication initiator': 'Atgriešanās pie autentifikācijas ierosinātāja',
  'Start the BankID app on your mobile device and tap the QR code button in the app to scan the QR code on this page': 'Sāciet lietotni BankID savā mobilajā ierīcē un pieskarieties pogai QR kods, lai skenētu QR kodu šajā lapā',
  'or': 'vai',
  'Starting authorization': 'Sāk autorizāciju',
  'Start typing for filtering the list': 'Sāciet rakstīt, lai filtrētu sarakstu',
  'To continue please use your authentication app': 'Lai turpinātu, lūdzu, izmantojiet savu autentifikācijas lietotni',
  'Waiting for completion of authentication': 'Gaida autentifikācijas pabeigšanu',
  'Try again': 'Mēģini vēlreiz',
  'Submit': 'Iesniegt',
  'Unable to access server': 'Nevar piekļūt serverim',
  'Unable to authenticate': 'Nevar autentificēt',
  'Unable to continue authorization': 'Nevar turpināt autorizāciju',
  'Unable to continue decoupled authorization': 'Nevar turpināt atsaistīto autorizāciju',
  'Unable to handle authentication status "{status}"': 'Nevar apstrādāt autentifikācijas statusu "{status}"',
  'Unknown error occurred': 'Radās nezināma kļūda',
  'You need to close this window': 'Jums ir jāaizver šis logs',
  // Consent
  'Authentication is initiated by {0}.': 'Autentifikāciju ierosina {0}.',
  'After you complete authentication, {0} will be shared with the authentication initiator.': 'Pēc autentifikācijas pabeigšanas {0} tiks kopīgoti ar autentifikācijas ierosinātāju.',
  'your payment accounts data': 'jūsu maksājumu kontu dati',
  'the list of your payment accounts': 'jūsu maksājumu kontu sarakstu',
  'the list of your payment accounts and accounts balances': 'jūsu maksājumu kontu saraksts un kontu atlikumi',
  'the list of your payment accounts and accounts transactions': 'jūsu maksājumu kontu sarakstu un kontu transakcijas',
  'the list of your payment accounts, accounts balances and transactions': 'jūsu maksājumu kontu, kontu atlikumu un darījumu saraksts',
  'By pressing {0} button you agree with the {1}.': 'Nospiežot pogu {0}, jūs piekrītat {1}.',
  'terms of Enable Banking API service': 'Enable Banking API pakalpojuma noteikumi',
  'Press {0} button if you are not willing to share your payment accounts data.': 'Nospiediet pogu {0}, ja nevēlaties kopīgot maksājumu kontu datus.',
  // Payment consent
  'Payment details': 'Maksājumu informācija',
  'Payment ID provided by the application: {0}': 'Lietojumprogrammas nodrošinātais maksājuma ID: {0}',
  'Account number where to transfer funds: {0}': 'Konta numurs, uz kuru pārskaitīt līdzekļus: {0}',
  'Payee name in the payment: {0}': 'Maksājuma saņēmēja vārds maksājumā: {0}',
  'Payee country: {0}': 'Maksājuma saņēmēja valsts: {0}',
  'Payment amount: {0}': 'Maksājuma summa: {0}',
  'Message or reference number in the payment: {0}': 'Ziņojums vai atsauces numurs maksājumā: {0}',
  'Payment is initiated by {0}.': 'Maksājumu ierosina {0}.',
  'After you complete authentication, your payment consent will be requested by your account servicing payment service provider.': 'Pēc autentifikācijas pabeigšanas jūsu kontu apkalpojošais maksājumu pakalpojumu sniedzējs pieprasīs jūsu maksājuma piekrišanu.',
  'terms of service': 'pakalpojumu sniegšanas noteikumi',
  'Press {0} button if you are not willing to complete this payment.': 'Nospiediet pogu {0}, ja nevēlaties pabeigt šo maksājumu.',
  'Select Payment Account': 'Atlasiet maksājumu konts',
  'Confirm': 'Apstiprināt',
  // About Tilisy
  'Enable Banking API is an online service that enables you to use your payment account data from a chosen bank or a similar organization (Account Servicing Payment Service Provider, the ASPSP) in other digital services.': 'Enable Banking API ir tiešsaistes pakalpojums, kas ļauj citos digitālajos pakalpojumos izmantot norēķinu konta datus no izvēlētās bankas vai līdzīgas organizācijas (kontu apkalpojošo maksājumu pakalpojumu sniedzēju, ASPSP).',
  'To use your payment account data, you must authenticate in the ASPSP service.': 'Lai izmantotu maksājumu konta datus, jums ir jāveic autentifikācija ASPSP pakalpojumā.',
  'The service was formerly known as Tilisy business API.': 'Pakalpojums agrāk bija pazīstams kā Tilisy biznesa API.',
  'Your explicit consent to use the payment account data will be maintained in Enable Banking API.': 'Jūsu skaidra piekrišana izmantot maksājumu konta datus tiks saglabāta Enable Banking API.',
  'This service registers your PSU authentication tokens and consent ID.': 'Šis pakalpojums reģistrē jūsu PSU autentifikācijas marķierus un piekrišanas ID.',
  'Once you have given the consent in the ASPSP service Enable Banking API opens access to your payment account data so that the digital service you are using (i.e. the authentication initiator) can retrieve this data.': 'Kad esat devis piekrišanu ASPSP pakalpojumā, Enable Banking API atver piekļuvi jūsu maksājumu konta datiem, lai jūsu izmantotais digitālais pakalpojums (t.i., autentifikācijas iniciators) varētu iegūt šos datus.',
  'Your payment account data flows through Enable Banking API and won\'t be registered there.': 'Jūsu maksājumu konta dati plūst caur Enable Banking API un netiks tur reģistrēti.',
  'Enable Banking API is owned and operated by Enable Banking Oy, located in Otakaari 5, 02150 Espoo, Finland, company ID 2988499-7.': 'Enable Banking API pieder un to pārvalda Enable Banking Oy, kas atrodas Otakaari 5, 02150 Espo, Somija, uzņēmuma ID 2988499-7.',
  'The company is a registered service provider (Account Information Service Provider, AISP) and supervised by the Finnish Financial Supervisory Authority (the FIN-FSA).': 'Uzņēmums ir reģistrēts pakalpojumu sniedzējs (konta informācijas pakalpojumu sniedzējs, AISP), un to uzrauga Somijas finanšu uzraudzības iestāde (FIN-FSA).',
  'The registration details can be found from {0}.': 'Reģistrācijas informāciju varat atrast vietnē {0}.',
  'the FIN-FSA register for payment services providers': 'FIN-FSA maksājumu pakalpojumu sniedzēju reģistrs',
  'Please read the {0} carefully before continuing to authentication.': 'Pirms turpināt autentificēšanu, lūdzu, uzmanīgi izlasiet {0}.',
  'You can check the terms and additional information on Enable Banking API service anytime from {0}.': 'Noteikumus un papildinformāciju par Enable Banking API pakalpojumu jebkurā laikā varat pārbaudīt vietnē {0}.',
  'Information and updates on service or fraudulent or corresponding activity threatening the secure use of the service will be communicated to you by your digital service provider together with Enable Banking Oy.': 'Informāciju un atjauninājumus par pakalpojumu vai krāpnieciskām vai atbilstošām darbībām, kas apdraud drošu pakalpojuma izmantošanu, jums paziņos jūsu digitālo pakalpojumu sniedzējs kopā ar Enable Banking Oy.',
  // Terms of Service
  'Terms of Service': 'Pakalpojuma noteikumi',
  'Last updated:': 'Pēdējoreiz atjaunots:',
  'Please read these terms of service carefully before using Enable Banking API.': 'Pirms izmantojat Enable Banking API, lūdzu, uzmanīgi izlasiet šos pakalpojumu sniegšanas noteikumus.',
  'Description of the service': 'Pakalpojuma apraksts',
  'Processing of personal data': 'Personas datu apstrāde',
  'Enable Banking processes personal data only for the purpose and to the extent that Enable Banking API service can function for you.': 'Enable Banking apstrādā personas datus tikai nolūkā un tiktāl, ciktāl Enable Banking API pakalpojums var darboties jūsu vietā.',
  'When processing personal data Enable Banking API undertakes to comply with the legislation relating to processing of personal data and data protection as well as other legislation applicable and especially the EU General Data Protection Regulation (EU 2016/679).': 'Apstrādājot personas datus, Enable Banking API apņemas ievērot tiesību aktus, kas attiecas uz personas datu apstrādi un datu aizsardzību, kā arī citus piemērojamos tiesību aktus, jo īpaši ES Vispārējo datu aizsardzības regulu (ES 2016/679).',
  'The more detailed contents of the personal data processing, including:': 'Sīkāks personas datu apstrādes saturs, tostarp:',
  '(a) the nature and purpose of the processing;': '(a) apstrādes veids un mērķis;',
  '(b) the type of personal data and categories of data subjects;': '(b) personas datu veids un datu subjektu kategorijas;',
  '(c) the applicable data protection measures;': '(c) piemērojamos datu aizsardzības pasākumus;',
  '(d) the customer’s rights and responsibilities;': '(d) klienta tiesības un pienākumi;',
  'and (e) the object and duration of the personal data processing shall be described in greater detail in the Privacy Policy.': 'un (e) personas datu apstrādes objekts un ilgums sīkāk aprakstīts konfidencialitātes politikā.',
  'Links to other websites': 'Saites uz citām vietnēm',
  'Enable Banking may contain links or redirect you to third-party web sites or services that are not owned or controlled by the Company, including but not limited to ASPSPs’ web sites or services.': 'Enable Banking var saturēt saites vai novirzīt jūs uz trešo pušu vietnēm vai pakalpojumiem, kas nepieder Sabiedrībai vai to nekontrolē, ieskaitot, bet neaprobežojoties ar ASPSP vietnēm vai pakalpojumiem.',
  'The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services.': 'Uzņēmums nekontrolē un neuzņemas atbildību par trešo pušu vietņu vai pakalpojumu saturu, konfidencialitātes politiku vai praksi.',
  'You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.': 'Jūs arī atzīstat un piekrītat, ka Uzņēmums nav tieši vai netieši atbildīgs vai atbildīgs par jebkādiem zaudējumiem vai zaudējumiem, ko izraisījis vai varētu izraisīt jebkāda veida šāda satura, preču vai pakalpojumu izmantošana vai paļaušanās uz tiem vai izmantojot jebkuras šādas tīmekļa vietnes vai pakalpojumus.',
  'We strongly advise you to read terms and conditions and privacy policies of any third-party web sites or services that you visit.': 'Mēs iesakām jums izlasīt trešo personu vietņu vai pakalpojumu, kurus apmeklējat, noteikumus un nosacījumus.',
  'No warranty': 'Bez Garantijas',
  'Without limiting the foregoing, neither the Company nor any of the company\'s provider makes any representation or warranty of any kind, express or implied:': 'Neierobežojot iepriekš minēto, ne uzņēmums, ne kāds no uzņēmuma pakalpojumu sniedzējiem neizsaka nekādas tiešas vai netiešas garantijas:',
  '(i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon;': '(i) attiecībā uz Pakalpojuma darbību vai pieejamību, vai tajā ietverto informāciju, saturu, materiāliem vai produktiem;',
  '(ii) that the Service will be uninterrupted or error-free;': '(ii) ka Pakalpojums būs nepārtraukts vai bez kļūdām;',
  '(iii) as to the accuracy, reliability, or currency of any information or content provided through the Service.': '(iii) par jebkuras ar Pakalpojumu sniegtās informācijas vai satura precizitāti, uzticamību vai valūtu.',
  'Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.': 'Dažās jurisdikcijās nav atļauts izslēgt noteikta veida garantijas vai ierobežojumus attiecībā uz piemērojamām likumīgām patērētāja tiesībām, tāpēc daži vai visi iepriekš minētie izņēmumi un ierobežojumi, iespējams, neattiecas uz jums.',
  'But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.': 'Bet šādā gadījumā šajā sadaļā izklāstītos izņēmumus un ierobežojumus piemēro vislielākajā mērā, kas ir izpildāms saskaņā ar piemērojamiem tiesību aktiem.',
  'Changes': 'Izmaiņas',
  'The Company reserves the right to modify or replace the Service at any time.': 'Uzņēmums patur tiesības jebkurā laikā mainīt vai aizstāt Pakalpojumu.',
  'If a revision is material the Company will make reasonable efforts to notify you prior to any new terms taking effect together with other digital service providers.': 'Ja pārskatīšana ir būtiska, Uzņēmums pieliks saprātīgas pūles, lai jūs informētu, pirms visi jauni noteikumi stājas spēkā kopā ar citiem digitālo pakalpojumu sniedzējiem.',
  'You can also check any updates from this page.': 'Šajā lapā varat arī pārbaudīt visus atjauninājumus.',
  'By continuing to access or use the Service after those revisions become effective, you agree to be bound by the revised terms.': 'Turpinot piekļuvi Pakalpojumam vai tā izmantošanu pēc šo labojumu stāšanās spēkā, jūs piekrītat, ka pārskatītie noteikumi ir saistoši.',
  'If you do not agree to the new terms, in whole or in part, please stop using the Service.': 'Ja jūs pilnībā vai daļēji nepiekrītat jaunajiem noteikumiem, lūdzu, pārtrauciet izmantot Pakalpojumu.',
  'Termination': 'Izbeigšana',
  'If for any reason, you no longer wish to use the Service to share your payment accounts data with other digital services, please {0}.': 'Ja jebkura iemesla dēļ jūs vairs negribat lietot Pakalpojumu, lai dalītos ar savām maksājumu kontu datiem citām digitālajām pakalpojumiem, lūdzu, {0}.',
  'visit the Data Sharing Consents page': 'apmeklējiet Datu Koplietošanas Piekrišanas lapu',
  'Upon termination, your right to use the Service will cease immediately.': 'Pēc līguma izbeigšanas jūsu tiesības izmantot Pakalpojumu nekavējoties zaudēs spēku.',
  'We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever.': 'Jebkura iemesla dēļ mēs varam nekavējoties pārtraukt vai apturēt jūsu piekļuvi bez iepriekšēja brīdinājuma vai atbildības.',
  'Governing law': 'Spēkā esošie tiesību akti',
  'The laws of Finland shall govern your use of the Service.': 'Pakalpojuma izmantošanu regulē Somijas likumi.',
  'If you are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.': 'Ja esat Eiropas Savienības patērētājs, jūs izmantosiet visus obligātos tās valsts tiesību aktu noteikumus, kurā dzīvojat.',
  'Disputes resolution': 'Strīdu izšķiršana',
  'If you have any concern or dispute about the Service, at first, you are asked for trying to resolve the dispute informally by contacting the Company.': 'Ja jums ir kādas bažas vai strīds par Pakalpojumu, vispirms jums tiek lūgts mēģināt atrisināt strīdu neoficiāli, sazinoties ar Uzņēmumu.',
  'The consumer users in Finland are also entitled to be in contact with Consumer Disputes Board, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tel. +358 29 566 5200, {0}.': 'Patērētājiem Somijā ir tiesības sazināties arī ar Patērētāju strīdu padomi, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tālr. +358 29 566 5200, {0}.',
  'The company related issues shall be directed to the supervising authority.': 'Ar sabiedrību saistītie jautājumi tiks novirzīti uzraudzības iestādei.',
  'The FIN-FSA contact information is: Snellmaninkatu 6, 00100 Helsinki, Finland, tel. +358 9 183 51 (switchboard); www.finanssivalvonta.fi.': 'FIN-FSA kontaktinformācija ir: Snellmaninkatu 6, 00100 Helsinki, Somija, tālr. +358 9 183 51 (sadales skapis); www.finanssivalvonta.fi.',
  'Contact us': 'Sazinies ar mums',
  'If you have any questions about this terms of service, you can contact us by email at {0}.': 'Ja jums ir kādi jautājumi par šiem pakalpojumu sniegšanas noteikumiem, varat sazināties ar mums pa e-pastu vietnē {0}.',
  // Backend messages
  '10 digit client number': '10 ciparu klienta numurs',
  '10 digit organisation number (or 12 digit personal number if the company is a sole proprietorship)': '10 ciparu organizācijas numurs (vai 12 ciparu personas numurs, ja uzņēmums ir individuālais uzņēmums)',
  '10 digit organization number.': '10 ciparu organizācijas numurs.',
  '17 digit card number (starting with 6703)': '17 ciparu kartes numurs (sākot ar 6703)',
  '6 digit Handelsbanken customer number': '6 ciparu Handelsbanken klienta numurs',
  '8 digits CVR number': '8 ciparu CVR numurs',
  '9 digits Organisasjonsnummer': '9 ciparu Organisasjonsnummer',
  'Agreement number': 'Līguma numurs',
  'Agreement number for Nordea Corporate Netbank': 'Nordea korporatīvās internetbankas līguma numurs',
  'Bank user ID': 'Bankas lietotāja ID',
  'Business identity code (Y-tunnus) in 1234567-8 format': 'Uzņēmuma identifikācijas kods (Y-tunnus) 1234567-8 formātā',
  'Business identity code which is displayed in the Corporate Internet bank settings': 'Uzņēmuma identifikācijas kods, kas tiek parādīts Korporatīvās internetbankas iestatījumos',
  'Client ID': 'Klienta ID',
  'Client number': 'Klienta numurs',
  'Company ID': 'Uzņēmuma ID',
  'Company number': 'Uzņēmuma numurs',
  'Corporate ID': 'Uzņēmuma ID',
  'Corporate registration ID': 'Uzņēmuma reģistrācijas ID',
  'Currency code': 'Valūtas kods',
  'Digit card number': 'Ciparu kartes numurs',
  'Disposer': 'Iznīcinātājs',
  'E-mail': 'E-pasts',
  'E-mail used for authenticating to Holvi': 'Holvi autentifikācijai izmantotais e-pasts',
  'Either the 14 digit SEB identifier for the company or the 10 digit organization number': 'Uzņēmuma 14 ciparu SEB identifikators vai 10 ciparu organizācijas numurs',
  'FKDN (without subaccount number): 3 digit branch number + 7 digit customer number': 'FKDN (bez subkonta numura): 3 ciparu filiāles numurs + 7 ciparu klienta numurs',
  'For Swedish Corporates, this is their Organisation number or SHB number, and for Sole Traders (Enskild firma), it is their Personal number, all of which are 10 digits.': 'Zviedrijas uzņēmumiem tas ir organizācijas numurs vai SHB numurs, savukārt individuālajiem tirgotājiem (Enskild firma) tas ir viņu personīgais numurs, kas visi sastāv no 10 cipariem.',
  'IBAN': 'IBAN',
  'ID, NIE, Passport': 'ID, NIE, pase',
  'Login ID used in MyBRD aplications': 'MyBRD lietojumprogrammās izmantotais pieteikšanās ID',
  'Login Name': 'Lietotāja vārdu',
  'Mandate ID': 'Mandāta ID',
  'Norwegian national identity number in the format DDMMYYXXXXX (11 digits)': 'Norvēģijas valsts identifikācijas numurs formātā DDMMYYXXXXX (11 cipari)',
  'Online banking PIN': 'Internetbankas PIN',
  'Online banking Password': 'Internetbankas parole',
  'Organisation ID in the format TBxxxxx or TXxxxxx': 'Organizācijas ID formātā TBxxxxx vai TXxxxxx',
  'Organisation number': 'Organizācijas numurs',
  'PIN': 'PIN',
  'Password': 'Parole',
  'Password for Aktia Internet bank': 'Aktia internetbankas parole',
  'Password used for authenticating to Holvi': 'Parole, kas izmantota autentifikācijai Holvi',
  'Personal ID': 'Personas ID',
  'Personal ID number': 'Personas kods',
  'Personal code': 'Personas kods',
  'Personal code of the user': 'Lietotāja personas kods',
  'Personal signatory number': 'Personīgais paraksta numurs',
  'Personal username for online banking': 'Personīgais lietotājvārds internetbankai',
  'Postbank ID': 'Pasta bankas ID',
  'Social security number (Personnummer)': 'Sociālās apdrošināšanas numurs (personas numurs)',
  'Social security number in the format YYYYMMDDXXXX': 'Sociālās apdrošināšanas numurs šādā formātā: GGGGMMDDXXXX',
  'Swedish social security number in the format YYYYMMDDXXXX': 'Zviedrijas sociālās apdrošināšanas numurs šādā formātā: GGGGMMDDXXXX',
  'The IBAN of the user authenticating': 'Lietotāja autentifikācijas IBAN',
  'The currency code': 'Valūtas kods',
  'The user ID of the user authenticating': 'Lietotāja autentifikācijas lietotāja ID',
  'Unique personal ID number': 'Unikāls personas kods',
  'User ID': 'Lietotāja ID',
  'User ID for Aktia Internet bank': 'Aktia internetbankas lietotāja ID',
  'User ID for Nordea Corporate Netbank': 'Nordea korporatīvās internetbankas lietotāja ID',
  'User ID for online banking': 'Lietotāja ID internetbankai',
  'User ID in the format XX000000': 'Lietotāja ID formātā XX000000',
  'User ID used to log in to the bank': 'Lietotāja ID, ko izmanto, lai pieteiktos bankā',
  'Username': 'Lietotājvārds',
  'Username used to log in to the bank': 'Lietotājvārds, ko izmanto, lai pieteiktos bankā',
  'VR NetKey': 'VR NetKey',
  'WebSSO user name': 'WebSSO lietotājvārds'
}
