import { defineCustomElement, getCurrentInstance } from 'vue'
import { i18n } from './i18n'
import Consent from './widgets/Consent.vue'
import AspspsList from './widgets/AspspsList.vue'
import AuthFlow from './widgets/AuthFlow.vue'

// Create a wrapper function that injects i18n
const withI18n = (component) => ({
  ...component,
  setup () {
    // Make i18n available to the component
    const app = getCurrentInstance()
    if (app) {
      app.appContext.app.use(i18n)
    }
  }
})

// Create separate constructors for each custom element
const TilisyConsentElement = defineCustomElement(withI18n(Consent), {
  shadowRoot: false
})
const EnablebankingConsentElement = defineCustomElement(withI18n(Consent), {
  shadowRoot: false
})
const AspspsListElement = defineCustomElement(withI18n(AspspsList), {
  shadowRoot: false
})
const AuthFlowElement = defineCustomElement(withI18n(AuthFlow), {
  shadowRoot: false
})

// Register custom elements
if (!window.customElements.get('enablebanking-consent')) {
  window.customElements.define('enablebanking-consent', EnablebankingConsentElement)
}
if (!window.customElements.get('enablebanking-aspsp-list')) {
  window.customElements.define('enablebanking-aspsp-list', AspspsListElement)
}
if (!window.customElements.get('enablebanking-auth-flow')) {
  window.customElements.define('enablebanking-auth-flow', AuthFlowElement)
}
// Deprecated "tilisy" element and global object
if (!window.customElements.get('tilisy-consent')) {
  window.customElements.define('tilisy-consent', TilisyConsentElement)
}

// Global enablebanking object with i18n object used for custom translations
window.enablebanking = {
  i18n: i18n.global
}

window.tilisy = {
  i18n: i18n.global
}
