export default{
  // General
  'About {appName}': 'Om {appName}',
  'About Enable Banking API': 'Om Enable Banking API',
  'Application description is not provided.': 'Søknadsbeskrivelse er ikke gitt.',
  'Authenticate': 'Autentiser',
  'Authentication method': 'Godkjenningsmetode',
  'Authentication session has expired': 'Autentiseringsøkten er utløpt',
  'Cancel': 'Avbryt',
  'Click {0} button if you have confirmed authorization in the BankID app.': 'Klikk på {0} -knappen hvis du har bekreftet autorisasjon i BankID-appen.',
  'Continue authentication': 'Fortsett autentisering',
  'Enter your OTP code with index {0}': 'Skriv inn OTP-koden din med indeksen {0}',
  'Error during authentication:': 'Feil under autentisering:',
  'Error while updating authentication status': 'Feil under oppdatering av godkjenningsstatus',
  'Open the BankID app on this device': 'Åpne BankID-appen på denne enheten',
  'OTP code': 'OTP-kode',
  'Redirecting to your account servicing provider': 'Viderekobler til kontotjenesteleverandøren',
  'Returning to the authentication initiator': 'Går tilbake til autentiseringsinitiatoren',
  'Start the BankID app on your mobile device and tap the QR code button in the app to scan the QR code on this page': 'Start BankID-appen på mobilenheten din og trykk på QR-kodeknappen i appen for å skanne QR-koden på denne siden',
  'or': 'eller',
  'Starting authorization': 'Starter autorisasjon',
  'Start typing for filtering the list': 'Begynn å skrive for å filtrere listen',
  'To continue please use your authentication app': 'For å fortsette, bruk autentiseringsappen din',
  'Waiting for completion of authentication': 'Venter på fullføring av autentisering',
  'Try again': 'Prøv igjen',
  'Submit': 'Sende inn',
  'Unable to access server': 'Kan ikke få tilgang til serveren',
  'Unable to authenticate': 'Kan ikke autentisere',
  'Unable to continue authorization': 'Kan ikke fortsette autorisasjonen',
  'Unable to continue decoupled authorization': 'Kan ikke fortsette frakoblet autorisasjon',
  'Unable to handle authentication status "{status}"': 'Kan ikke håndtere autentiseringsstatusen "{status}"',
  'Unknown error occurred': 'Ukjent feil oppstod',
  'You need to close this window': 'Du må lukke dette vinduet',
  // Consent
  'Authentication is initiated by {0}.': 'Autentisering initieres av {0}.',
  'After you complete authentication, {0} will be shared with the authentication initiator.': 'Etter at du har fullført autentiseringen, blir {0} delt med autentiseringsinitiatoren.',
  'your payment accounts data': 'betalingskontodataene dine',
  'the list of your payment accounts': 'listen over betalingskontoene dine',
  'the list of your payment accounts and accounts balances': 'listen over betalingskontoene og kontosaldoene dine',
  'the list of your payment accounts and accounts transactions': 'listen over betalingskontoene og kontotransaksjonene dine',
  'the list of your payment accounts, accounts balances and transactions': 'listen over betalingskontoer, kontosaldoer og transaksjoner',
  'By pressing {0} button you agree with the {1}.': 'Ved å trykke på {0} godtar du {1}.',
  'terms of Enable Banking API service': 'vilkårene for Enable Banking API-tjenesten',
  'Press {0} button if you are not willing to share your payment accounts data.': 'Trykk på {0} -knappen hvis du ikke er villig til å dele dataene om betalingskontoen din.',
  // About Tilisy
  'Enable Banking API is an online service that enables you to use your payment account data from a chosen bank or a similar organization (Account Servicing Payment Service Provider, the ASPSP) in other digital services.': 'Enable Banking API er en online tjeneste som lar deg bruke betalingskontodataene dine fra en valgt bank eller en lignende organisasjon (Account Servicing Payment Service Provider, ASPSP) i andre digitale tjenester.',
  'To use your payment account data, you must authenticate in the ASPSP service.': 'For å bruke betalingskontodataene dine, må du godkjenne i ASPSP-tjenesten.',
  'The service was formerly known as Tilisy business API.': 'Tjenesten var tidligere kjent som Tilisy business API.',
  'Your explicit consent to use the payment account data will be maintained in Enable Banking API.': 'Ditt eksplisitte samtykke til å bruke betalingskontodataene opprettholdes i Enable Banking API.',
  'This service registers your PSU authentication tokens and consent ID.': 'Denne tjenesten registrerer PSU-godkjenningstokener og samtykke-ID.',
  'Once you have given the consent in the ASPSP service Enable Banking API opens access to your payment account data so that the digital service you are using (i.e. the authentication initiator) can retrieve this data.': 'Når du har gitt samtykke i ASPSP-tjenesten, åpner Enable Banking API tilgang til betalingskontodataene dine slik at den digitale tjenesten du bruker (dvs. autentiseringsinitiatoren) kan hente disse dataene.',
  'Your payment account data flows through Enable Banking API and won\'t be registered there.': 'Betalingsdataene dine strømmer gjennom Enable Banking API og blir ikke registrert der.',
  'Enable Banking API is owned and operated by Enable Banking Oy, located in Otakaari 5, 02150 Espoo, Finland, company ID 2988499-7.': 'Enable Banking API eies og drives av Enable Banking Oy, som ligger i Otakaari 5, 02150 Espoo, Finland, firma-ID 2988499-7.',
  'The company is a registered service provider (Account Information Service Provider, AISP) and supervised by the Finnish Financial Supervisory Authority (the FIN-FSA).': 'Selskapet er en registrert tjenesteleverandør (Account Information Service Provider, AISP) og overvåkes av Finlands finanstilsyn (FIN-FSA).',
  'The registration details can be found from {0}.': 'Registreringsdetaljene finner du fra {0}.',
  'the FIN-FSA register for payment services providers': 'FIN-FSA-registeret for leverandører av betalingstjenester',
  'Please read the {0} carefully before continuing to authentication.': 'Les {0} nøye før du fortsetter til autentisering.',
  'You can check the terms and additional information on Enable Banking API service anytime from {0}.': 'Du kan sjekke vilkårene og tilleggsinformasjonen i Enable Banking API-tjenesten når som helst fra {0}.',
  'Information and updates on service or fraudulent or corresponding activity threatening the secure use of the service will be communicated to you by your digital service provider together with Enable Banking Oy.': 'Informasjon og oppdateringer om tjenester eller falske eller tilsvarende aktiviteter som truer sikker bruk av tjenesten vil bli kommunisert til deg av din digitale tjenesteleverandør sammen med Enable Banking Oy.',
  // Terms of Service
  'Terms of Service': 'Vilkår for bruk',
  'Last updated:': 'Sist oppdatert:',
  'Please read these terms of service carefully before using Enable Banking API.': 'Les disse tjenestevilkårene nøye før du bruker Enable Banking API.',
  'Description of the service': 'Beskrivelse av tjenesten',
  'Processing of personal data': 'Behandling av personopplysninger',
  'Enable Banking processes personal data only for the purpose and to the extent that Enable Banking API service can function for you.': 'Enable Banking behandler bare personopplysninger for formålet og i den grad Enable Banking API-tjenesten kan fungere for deg.',
  'When processing personal data Enable Banking API undertakes to comply with the legislation relating to processing of personal data and data protection as well as other legislation applicable and especially the EU General Data Protection Regulation (EU 2016/679).': 'Ved behandling av personopplysninger forplikter Enable Banking API seg til å overholde lovgivningen som gjelder behandling av personopplysninger og databeskyttelse, samt annen lovgivning som gjelder, og spesielt EUs generelle databeskyttelsesforordning (EU 2016/679).',
  'The more detailed contents of the personal data processing, including:': 'Det mer detaljerte innholdet i behandlingen av personopplysningene, inkludert:',
  '(a) the nature and purpose of the processing;': '(a) arten og formålet med behandlingen;',
  '(b) the type of personal data and categories of data subjects;': '(b) typen personopplysninger og kategorier av registrerte;',
  '(c) the applicable data protection measures;': '(c) gjeldende databeskyttelsestiltak;',
  '(d) the customer’s rights and responsibilities;': '(d) kundens rettigheter og ansvar;',
  'and (e) the object and duration of the personal data processing shall be described in greater detail in the Privacy Policy.': 'og (e) gjenstanden og varigheten av behandlingen av personopplysningene skal beskrives nærmere i personvernreglene.',
  'Links to other websites': 'Lenker til andre nettsteder',
  'Enable Banking may contain links or redirect you to third-party web sites or services that are not owned or controlled by the Company, including but not limited to ASPSPs’ web sites or services.': 'Enable Banking kan inneholde lenker eller omdirigere deg til tredjeparts nettsteder eller tjenester som ikke eies eller kontrolleres av selskapet, inkludert men ikke begrenset til ASPSPs nettsteder eller tjenester.',
  'The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services.': 'Selskapet har ingen kontroll over og påtar seg intet ansvar for innholdet, personvernpolicyene eller fremgangsmåten til tredjeparts nettsteder eller tjenester.',
  'You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.': 'Du erkjenner videre og godtar at selskapet ikke skal være ansvarlig eller ansvarlig, direkte eller indirekte, for skader eller tap som er forårsaket eller påstått å være forårsaket av eller i forbindelse med bruk av eller avhengighet av slikt innhold, varer eller tjenester tilgjengelig på eller gjennom slike nettsteder eller tjenester.',
  'We strongly advise you to read terms and conditions and privacy policies of any third-party web sites or services that you visit.': 'Vi anbefaler deg på det sterkeste å lese vilkår og betingelser og personvernregler for tredjeparts nettsteder eller tjenester du besøker.',
  'No warranty': 'Ingen garanti',
  'Without limiting the foregoing, neither the Company nor any of the company\'s provider makes any representation or warranty of any kind, express or implied:': 'Uten å begrense det foregående, gir verken selskapet eller noen av selskapets leverandører noen garanti av noe slag, uttrykkelig eller underforstått:',
  '(i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon;': '(i) med hensyn til driften eller tilgjengeligheten av tjenesten, eller informasjonen, innholdet og materialene eller produktene som er inkludert der;',
  '(ii) that the Service will be uninterrupted or error-free;': '(ii) at tjenesten vil være uavbrutt eller feilfri;',
  '(iii) as to the accuracy, reliability, or currency of any information or content provided through the Service.': '(iii) med hensyn til nøyaktighet, pålitelighet eller valuta for informasjon eller innhold som tilbys gjennom tjenesten.',
  'Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.': 'Noen jurisdiksjoner tillater ikke utelukkelse av visse typer garantier eller begrensninger på gjeldende lovbestemte rettigheter til en forbruker, så noen eller alle av de ovennevnte unntakene og begrensningene gjelder kanskje ikke for deg.',
  'But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.': 'Men i et slikt tilfelle skal unntakene og begrensningene angitt i denne delen i størst mulig grad anvendes gjennom gjeldende lov.',
  'Changes': 'Endringer',
  'The Company reserves the right to modify or replace the Service at any time.': 'Selskapet forbeholder seg retten til når som helst å endre eller erstatte tjenesten.',
  'If a revision is material the Company will make reasonable efforts to notify you prior to any new terms taking effect together with other digital service providers.': 'Hvis en revisjon er vesentlig, vil selskapet gjøre rimelige anstrengelser for å varsle deg før nye vilkår trer i kraft sammen med andre digitale tjenesteleverandører.',
  'You can also check any updates from this page.': 'Du kan også sjekke oppdateringer fra denne siden.',
  'By continuing to access or use the Service after those revisions become effective, you agree to be bound by the revised terms.': 'Ved å fortsette å få tilgang til eller bruke tjenesten etter at disse revisjonene trer i kraft, godtar du å være bundet av de reviderte vilkårene.',
  'If you do not agree to the new terms, in whole or in part, please stop using the Service.': 'Hvis du ikke godtar de nye vilkårene, helt eller delvis, må du slutte å bruke tjenesten.',
  'Termination': 'Avslutning',
  'If for any reason, you no longer wish to use the Service to share your payment accounts data with other digital services, please {0}.': 'Hvis du av en eller annen grunn ikke lenger ønsker å bruke Tjenesten til å dele dine betalingskontodata med andre digitale tjenester, vennligst {0}.',
  'visit the Data Sharing Consents page': 'besøk siden for Samtykke til Data Deling',
  'Upon termination, your right to use the Service will cease immediately.': 'Ved oppsigelse opphører din rett til å bruke tjenesten umiddelbart.',
  'We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever.': 'Vi kan avslutte eller suspendere tilgangen din umiddelbart, uten forutgående varsel eller ansvar, av hvilken som helst grunn.',
  'Governing law': 'Gjeldende lov',
  'The laws of Finland shall govern your use of the Service.': 'Lovene i Finland styrer din bruk av tjenesten.',
  'If you are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.': 'Hvis du er en EU-forbruker, vil du dra nytte av alle obligatoriske bestemmelser i loven i landet du er bosatt i.',
  'Disputes resolution': 'Tvisteløsning',
  'If you have any concern or dispute about the Service, at first, you are asked for trying to resolve the dispute informally by contacting the Company.': 'Hvis du har noen bekymringer eller tvist om tjenesten, blir du først bedt om å prøve å løse tvisten uformelt ved å kontakte selskapet.',
  'The consumer users in Finland are also entitled to be in contact with Consumer Disputes Board, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tel. +358 29 566 5200, {0}.': 'Forbrukerbrukerne i Finland har også rett til å være i kontakt med Forbrukertviststyret, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tlf. +358 29 566 5200, {0}.',
  'The company related issues shall be directed to the supervising authority.': 'Selskapsrelaterte spørsmål skal rettes til tilsynsmyndigheten.',
  'The FIN-FSA contact information is: Snellmaninkatu 6, 00100 Helsinki, Finland, tel. +358 9 183 51 (switchboard); www.finanssivalvonta.fi.': 'FIN-FSA kontaktinformasjon er: Snellmaninkatu 6, 00100 Helsinki, Finland, tlf. +358 9 183 51 (sentralbord); www.finanssivalvonta.fi.',
  'Contact us': 'Kontakt oss',
  'If you have any questions about this terms of service, you can contact us by email at {0}.': 'Hvis du har spørsmål om disse vilkårene for bruk, kan du kontakte oss via e-post på {0}.',
  // Backend messages
  '10 digit client number': '10-sifret kundenummer',
  '10 digit organisation number (or 12 digit personal number if the company is a sole proprietorship)': '10-sifret organisasjonsnummer (eller 12-sifret personnummer hvis selskapet er et enkeltpersonforetak)',
  '10 digit organization number.': '10-sifret organisasjonsnummer.',
  '17 digit card number (starting with 6703)': '17-sifret kortnummer (begynner med 6703)',
  '6 digit Handelsbanken customer number': '6-sifret Handelsbanken kundenummer',
  '8 digits CVR number': '8 sifre CVR-nummer',
  '9 digits Organisasjonsnummer': '9 siffer Organisasjonsnummer',
  'Agreement number': 'Avtalenummer',
  'Agreement number for Nordea Corporate Netbank': 'Avtalenummer for Nordea Corporate Netbank',
  'Bank user ID': 'Bank bruker-ID',
  'Business identity code (Y-tunnus) in 1234567-8 format': 'Bedriftsidentitetskode (Y-tunnus) i 1234567-8 format',
  'Business identity code which is displayed in the Corporate Internet bank settings': 'Bedriftsidentitetskode som vises i innstillingene for bedriftens nettbank',
  'Client ID': 'Klient-ID',
  'Client number': 'Kundenummer',
  'Company ID': 'Bedrifts-ID',
  'Company number': 'Firmanummer',
  'Corporate ID': 'Bedrifts-ID',
  'Corporate registration ID': 'Bedriftsregistrerings-ID',
  'Currency code': 'Valutakode',
  'Digit card number': 'Sifferkortnummer',
  'Disposer': 'Avhender',
  'E-mail': 'E-post',
  'E-mail used for authenticating to Holvi': 'E-post brukt for autentisering til Holvi',
  'Either the 14 digit SEB identifier for the company or the 10 digit organization number': 'Enten den 14-sifrede SEB-identifikatoren for selskapet eller det 10-sifrede organisasjonsnummeret',
  'FKDN (without subaccount number): 3 digit branch number + 7 digit customer number': 'FKDN (uten underkontonummer): 3-sifret filialnummer + 7-sifret kundenummer',
  'For Swedish Corporates, this is their Organisation number or SHB number, and for Sole Traders (Enskild firma), it is their Personal number, all of which are 10 digits.': 'For svenske bedrifter er dette deres organisasjonsnummer eller SHB-nummer, og for eneforhandlere (Enskild firma) er det deres personnummer, som alle er 10 sifre.',
  'IBAN': 'IBAN',
  'ID, NIE, Passport': 'ID, NIE, pass',
  'Login ID used in MyBRD aplications': 'Påloggings-ID brukt i MyBRD-applikasjoner',
  'Login Name': 'Påloggingsnavn',
  'Mandate ID': 'Fullmakts-ID',
  'Norwegian national identity number in the format DDMMYYXXXXX (11 digits)': 'Norsk fødselsnummer i formatet DDMMYYXXXXXX (11 siffer)',
  'Online banking PIN': 'PIN-kode for nettbank',
  'Online banking Password': 'Passord for nettbank',
  'Organisation ID in the format TBxxxxx, TXxxxxx, TUxxxxx or NUxxxxx': 'Organisasjons-ID i formatet TBxxxxx eller TXxxxxx eller TUxxxxx eller NUxxxxx',
  'Organisation number': 'Organisasjonsnummer',
  'PIN': 'PIN',
  'Password': 'Passord',
  'Password for Aktia Internet bank': 'Passord for Aktia nettbank',
  'Password used for authenticating to Holvi': 'Passord brukt for autentisering til Holvi',
  'Personal ID': 'Personlig ID',
  'Personal ID number': 'Personlig ID-nummer',
  'Personal code': 'Personlig kode',
  'Personal code of the user': 'Personlig kode til brukeren',
  'Personal signatory number': 'Personlig underskriftsnummer',
  'Personal username for online banking': 'Personlig brukernavn for nettbank',
  'Postbank ID': 'Postbank ID',
  'Social security number (Personnummer)': 'Personnummer (personnummer)',
  'Social security number in the format YYYYMMDDXXXX': 'Personnummer i formatet ÅÅÅÅMMDDXXXX',
  'Swedish social security number in the format YYYYMMDDXXXX': 'Svensk personnummer i formatet ÅÅÅÅMMDDXXXX',
  'The IBAN of the user authenticating': 'IBAN til brukeren som autentiserer',
  'The currency code': 'Valutakoden',
  'The user ID of the user authenticating': 'Bruker-ID-en til brukeren som autentiserer',
  'Unique personal ID number': 'Unikt personnummer',
  'User ID': 'Bruker-ID',
  'User ID for Aktia Internet bank': 'Bruker-ID for Aktia nettbank',
  'User ID for Nordea Corporate Netbank': 'Bruker-ID for Nordea Corporate Netbank',
  'User ID for online banking': 'Bruker-ID for nettbank',
  'User ID in the format XX000000': 'Bruker-ID i formatet XX000000',
  'User ID used to log in to the bank': 'Bruker-ID brukt for å logge inn i banken',
  'Username': 'Brukernavn',
  'Username used to log in to the bank': 'Brukernavn brukt for å logge inn i banken',
  'VR NetKey': 'VR NetKey',
  'WebSSO user name': 'WebSSO brukernavn'
}
