import type { LocalizationConfigResponse } from "@again-enable-banking/api/localization/load";
import { logUnhandledSwitch } from "./unhandledSwitch";

export const READ_MORE_BUTTON_ID = "toggle-about";

type Props = {
  localization: LocalizationConfigResponse;
};

const getButtonText = ({ localization: { consentLocale } }: Props) => {
  switch (consentLocale) {
    case "NO":
      return "Les mer om Enable Banking ›";
    case "SV":
      return "Läs mer om Enable Banking ›";
    default:
      logUnhandledSwitch(consentLocale);
      return "Läs mer om Enable Banking ›";
  }
};

export const injectAboutReadMoreButton = (props: Props) => {
  if (document.getElementById(READ_MORE_BUTTON_ID)) {
    return;
  }

  const btn = document.createElement("button");
  btn.id = READ_MORE_BUTTON_ID;
  btn.className = "border-t p-4 font-light w-full text-xs";
  btn.innerText = getButtonText(props);

  btn.addEventListener("click", () => {
    const aboutDiv = document.querySelector(".tilisy-about");
    aboutDiv?.classList.toggle("collapsed");
    aboutDiv?.classList.toggle("opacity-0");
    setTimeout(() => {
      aboutDiv?.classList.toggle("animate-slideinup");
    }, 0);
  });

  const targetDiv = document.querySelector(".tilisy-about");
  if (targetDiv) {
    // insert the button before the targetDiv
    targetDiv.parentNode?.insertBefore(btn, targetDiv);
    targetDiv.classList.add("collapsed");
  } else {
    console.error("Target div not found, unable to inject read more button");
  }
};
