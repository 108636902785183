type Props = {
  onCancel: () => any;
};

export const ConsentDisplaySkeletonNo = ({ onCancel }: Props) => {
  return (
    <div className="tilisy-consent animate-pulse">
      <div className="tilisy-terms">
        <p className="text-justify">
          <span>
            Autentisering initieres av <strong>Again</strong>.
          </span>
          <span>&nbsp;</span>
          <span className="mr-1">
            Etter at du har fullført autentiseringen, blir{" "}
            <em>listen over betalingskontoer, kontosaldoer og transaksjoner</em>{" "}
            delt med autentiseringsinitiatoren.
          </span>
          <span>&nbsp;</span>
          <span>
            Ved å trykke på <span>"Fortsett autentisering"</span> godtar du{" "}
            <a
              href="https://tilisy-sandbox.enablebanking.com/terms?locale=NO"
              target="_blank"
            >
              vilkårene for Enable Banking API-tjenesten
            </a>
            .
          </span>
          <span>
            Trykk på <span>"Avbryt"</span> -knappen hvis du ikke er villig til å
            dele dataene om betalingskontoen din.
          </span>
        </p>
      </div>
      <div className="tilisy-confirmation">
        <div className="form-inline justify-content-center">
          <button className="btn btn-theme" disabled>
            Fortsett autentisering
          </button>
        </div>
        <div className="text-center">
          <button className="btn btn-link" onClick={onCancel}>
            Avbryt
          </button>
        </div>
      </div>
      <div className="tilisy-about">
        <p>
          <strong>Om Again</strong>
        </p>
        <p className="text-justify">Again Technology</p>
        <div>
          <p>
            <strong>Om Enable Banking API</strong>
          </p>
          <p className="text-justify">
            <span>
              Enable Banking API er en online tjeneste som lar deg bruke
              betalingskontodataene dine fra en valgt bank eller en lignende
              organisasjon (Account Servicing Payment Service Provider, ASPSP) i
              andre digitale tjenester.
            </span>
            <span>
              For å bruke betalingskontodataene dine, må du godkjenne i
              ASPSP-tjenesten.
            </span>
          </p>
          <p className="text-justify">
            <span>
              Ditt eksplisitte samtykke til å bruke betalingskontodataene
              opprettholdes i Enable Banking API.
            </span>
            <span>
              Denne tjenesten registrerer PSU-godkjenningstokener og
              samtykke-ID.
            </span>
            <span>
              Når du har gitt samtykke i ASPSP-tjenesten, åpner Enable Banking
              API tilgang til betalingskontodataene dine slik at den digitale
              tjenesten du bruker (dvs. autentiseringsinitiatoren) kan hente
              disse dataene.
            </span>
            <span>
              Betalingsdataene dine strømmer gjennom Enable Banking API og blir
              ikke registrert der.
            </span>
          </p>
          <p className="text-justify">
            <span>
              Enable Banking API eies og drives av Enable Banking Oy, som ligger
              i Otakaari 5, 02150 Espoo, Finland, firma-ID 2988499-7.
            </span>
            <span>
              Selskapet er en registrert tjenesteleverandør (Account Information
              Service Provider, AISP) og overvåkes av Finlands finanstilsyn
              (FIN-FSA).
            </span>
            <span>
              Registreringsdetaljene finner du fra
              <a
                href="https://www.finanssivalvonta.fi/en/registers/supervised-entities/"
                target="_blank"
              >
                FIN-FSA-registeret for leverandører av betalingstjenester
              </a>
              .
            </span>
          </p>
          <p className="text-justify">
            <span>
              Les
              <a href="https://tilisy.enablebanking.com/terms" target="_blank">
                vilkårene for Enable Banking API-tjenesten
              </a>
              nøye før du fortsetter til autentisering.
            </span>
            <span>
              Du kan sjekke vilkårene og tilleggsinformasjonen i Enable Banking
              API-tjenesten når som helst fra
              <a href="https://tilisy.enablebanking.com/terms" target="_blank">
                https://tilisy.enablebanking.com/terms
              </a>
              .
            </span>
            <span>
              Informasjon og oppdateringer om tjenester eller falske eller
              tilsvarende aktiviteter som truer sikker bruk av tjenesten vil bli
              kommunisert til deg av din digitale tjenesteleverandør sammen med
              Enable Banking Oy.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
