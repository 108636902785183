import api from './api'

export default class BaseService {
  static getSessionStatus () {
    return api.get('/ais/get_session_status', {
      params: {
        current_uri: window.location.href
      }
    })
  }

  static restoreSession (redirectUri) {
    return api.post('/ais/get_session', {
      redirect_uri: redirectUri
    })
  }

  static startAuthorization (method, credentials, locale) {
    const data = {}
    if (method) {
      data.method = method
    }
    if (credentials && Object.keys(credentials).length) {
      data.credentials = credentials
    }
    if (locale) {
      data.locale = locale
    }
    return api.post('/ais/start_authorization', data)
  }

  static continueAuthorization (data) {
    if (data === undefined) {
      data = {}
    }
    return api.post('/ais/continue_authorization', data)
  }

  static continueAuthorizationWithRedirectUrl (redirectUri, locale) {
    const data = {
      redirect_uri: redirectUri
    }
    if (locale) {
      data.locale = locale
    }
    return this.continueAuthorization(data)
  }

  static continueAuthorizationWithOtpCode (code, locale) {
    const data = {
      code: code
    }
    if (locale) {
      data.locale = locale
    }
    return this.continueAuthorization(data)
  }

  static notifyLeaving () {
    return api.post('/ais/leaving')
  }

  static confirmDataSharingConsent () {
    return api.post('/ais/confirm_data_sharing_consent')
  }

  static cancelAuth () {
    return api.post('/ais/cancel_authentication')
  }

  static setScheduleUpdateFunction (scheduleUpdateFunction) {
    this.scheduleUpdate = scheduleUpdateFunction
  }

  static async callPisApi (method, path, data) {
    try {
      console.log('Calling PIS API', method, path, data)
      return await api[method]('/pis' + path, data)
    } catch (error) {
      console.error('PIS API error', error)
      if (error.message === 'Network Error') {
        this.scheduleUpdate(500)
      }
      throw error
    }
  }

  static getPayment () {
    return this.callPisApi('get', '/payment')
  }

  static submitCredentials (method, credentials, locale) {
    const data = {}
    if (method) {
      data.method = method
    }
    if (credentials && Object.keys(credentials).length) {
      data.credentials = credentials
    }
    if (locale) {
      data.locale = locale
    }
    return this.callPisApi('post', '/credentials', data)
  }

  static submitAccountSelection (accountId, locale) {
    const data = {
      account_id: accountId
    }
    if (locale) {
      data.locale = locale
    }
    return this.callPisApi('post', '/select_account', data)
  }

  static getNextPage () {
    return api.get('/api/next_page', {
      params: {
        current_uri: window.location.href
      }
    })
  }

  static submitPaymentInitiation (locale) {
    const data = {}
    if (locale) {
      data.locale = locale
    }
    return this.callPisApi('post', '/payment', data)
  }

  static confirmPayment (params) {
    const data = {
      current_uri: window.location.href
    }
    if (Object.keys(params).length) {
      for (const key in params) {
        data[key] = params[key]
      }
    }
    return this.callPisApi('post', '/confirm_payment', data)
  }

  static cancelPayment () {
    return this.callPisApi('post', '/cancel')
  }

  static confirmPisDataSharingConsent () {
    return this.callPisApi('post', '/confirm_data_sharing_consent')
  }

  static notifyLeavingPis () {
    return this.callPisApi('post', '/leaving')
  }
}
