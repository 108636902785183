import type {
  ApiListAspspsResponse,
  ApiStartUserAuthorisationSuccessfulResponse,
} from "@again-enable-banking/api/index";
import { useCallback, useEffect, useState } from "preact/hooks";
import { startUserSession } from "../utils/api/startUserSession";
import { ErrorDisplay, type ErrorDisplayProps } from "./ErrorDisplay";
import { waitMs } from "../utils/waitMs";
import { ConsentDisplay } from "./ConsentDisplay";
import { AspspItem } from "./AspspItem";
import { AspspListHeader } from "./AspspListHeader";
import { AspspList } from "./AspspList";
import { postCancel } from "../utils/api/postCancel";
import { postConsentError } from "../utils/api/postConsentError";
import type { LocalizationConfigResponse } from "@again-enable-banking/api/localization/load";

type Props = {
  baseUrl: string;
  aspsps: ApiListAspspsResponse["aspsps"];
  topAspsps: ApiListAspspsResponse["topAspsps"];
  localization: LocalizationConfigResponse;
};

export const StartView = ({
  aspsps,
  topAspsps,
  baseUrl,
  localization,
}: Props) => {
  const [displayError, setDisplayError] = useState<ErrorDisplayProps | null>(
    null
  );
  const [showAspsps, setShowAspsps] = useState(true);
  const [selectedAspsp, setSelectedAspsp] = useState<
    Props["aspsps"][number] | null
  >(null);
  const [authorisation, setAuthorisation] =
    useState<ApiStartUserAuthorisationSuccessfulResponse | null>(null);

  const onBankSelectedHandler =
    (aspsp: Props["aspsps"][number]) => async () => {
      console.log("clicked", aspsp);

      document.querySelectorAll("li").forEach((li) => {
        li.classList.remove("animate-slidein");
        li.classList.remove("opacity-0");
        li.classList.add("pointer-events-none");
        li.classList.add("cursor-default");

        if (li.dataset.startLinkName !== aspsp.name) {
          li.classList.add("animate-slideout");
        } else {
          li.classList.add("animate-fadeselected");
        }
      });

      setSelectedAspsp(aspsp); // should render the selected-aspsp
      await waitMs(200); // wait until the slideout animations are done
      setShowAspsps(false); // hide the aspsps list
      document // reveal the selected-aspsp
        .getElementById("selected-aspsp")
        ?.classList.add("animate-slideinalt");

      window.scrollTo(0, 0);
    };

  useEffect(() => {
    if (selectedAspsp) {
      const searchParams = new URLSearchParams(document.location.search);
      startUserSession(baseUrl, {
        state: searchParams.get("state")!,
        name: selectedAspsp.name,
        country: selectedAspsp.country,
      })
        .then((response) => {
          if ("authorization_id" in response) {
            setAuthorisation(response);
          } else {
            console.error(response);
            setDisplayError(
              localization.languageCode === "nb"
                ? {
                    title: "Kunne ikke starte",
                    description: "Noe gikk galt da vi prøvde å starte økten",
                  }
                : {
                    title: "Kunde inte starta",
                    description:
                      "Något gick fel när vi skulle starta sessionen",
                  }
            );
          }
        })
        .catch(() => {
          setDisplayError(
            localization.languageCode === "nb"
              ? {
                  title: "Kunne ikke starte",
                  description: "Noe gikk galt da vi prøvde å starte økten",
                }
              : {
                  title: "Kunde inte starta",
                  description: "Något gick fel när vi skulle starta sessionen",
                }
          );
        });
    }
  }, [selectedAspsp]);

  const onConsentCancelled = useCallback(async () => {
    const aspspDiv = document.getElementById("selected-aspsp")!;
    aspspDiv.classList.remove("animate-slideinalt");
    aspspDiv.classList.remove("opacity-0");
    aspspDiv.classList.add("animate-slideoutalt");
    await Promise.allSettled([
      postCancel(baseUrl, new URLSearchParams(document.location.search)),
      waitMs(300),
    ]);
    setAuthorisation(null);
    setSelectedAspsp(null);
    setShowAspsps(true);
  }, [setSelectedAspsp, setShowAspsps]);

  const onConsentError = useCallback(
    (error: any) => {
      const params = new URLSearchParams(document.location.search);
      postConsentError(baseUrl, params, error);
      setDisplayError(
        localization.languageCode === "nb"
          ? {
              title: "Kunne ikke starte",
              description: "Noe gikk galt da vi prøvde å starte økten",
            }
          : {
              title: "Kunde inte starta",
              description: "Något gick fel när vi skulle starta sessionen",
            }
      );
    },
    [setDisplayError]
  );

  if (!aspsps) {
    return <div>Temporary error: unable to load list</div>;
  }

  if (displayError) {
    return <ErrorDisplay {...displayError} />;
  }

  return (
    <main className="space-y-2 p-4 pb-16 container mx-auto">
      {showAspsps === true ? (
        <>
          {topAspsps.length > 0 ? (
            <>
              <AspspListHeader
                title={
                  localization.languageCode === "nb"
                    ? "Vanlige banker"
                    : "Vanligaste bankerna"
                }
              />
              <AspspList id="top-aspsps">
                {topAspsps.map((aspsp, i) => (
                  <AspspItem
                    key={aspsp.name}
                    delayIndex={i}
                    onClick={onBankSelectedHandler(aspsp)}
                    aspsp={aspsp}
                  />
                ))}
              </AspspList>
            </>
          ) : null}
          <AspspListHeader
            title={
              localization.languageCode === "nb" ? "Alle banker" : "Alla banker"
            }
          />
          <AspspList id="all-aspsps">
            {aspsps.map((aspsp, i) => (
              <AspspItem
                key={aspsp.name}
                delayIndex={i}
                onClick={onBankSelectedHandler(aspsp)}
                aspsp={aspsp}
              />
            ))}
          </AspspList>
        </>
      ) : null}

      {selectedAspsp ? (
        <div
          id="selected-aspsp"
          className="flex flex-col opacity-0 max-w-lg mx-auto"
        >
          <div className="h-8 max-w-54 mt-4 mb-8 mx-auto">
            <img
              src={`${selectedAspsp.logo}-/resize/500x/`}
              alt={selectedAspsp.name}
              className="h-8 max-w-40 my-4 mx-auto object-contain"
            />
          </div>
          <ConsentDisplay
            baseUrl={baseUrl}
            localization={localization}
            response={authorisation}
            onError={onConsentError}
            onCancel={onConsentCancelled}
          />
        </div>
      ) : null}
    </main>
  );
};
