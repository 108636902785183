import { useEffect, useState } from "preact/hooks";
import { Progress } from "./Progress";
import { checkAuthorised } from "../utils/api/checkAuthorised";
import { dispatchCompleted } from "../utils/dispatchMessage";
import type { LocalizationConfigResponse } from "@again-enable-banking/api/localization/load";

type Props = {
  baseUrl: string;
  onCancel: () => any;
  localization: LocalizationConfigResponse;
};

const CHECK_INTERVAL_MS = 1000;

export const ExternalBrowserWaiting = ({
  baseUrl,
  onCancel,
  localization,
}: Props) => {
  const [showCancel, setShowCancel] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShowCancel(true), 10000);
    return () => clearTimeout(timeout);
  }, [setShowCancel]);

  useEffect(() => {
    let timeout: any;

    const doCheck = async () => {
      try {
        const params = new URLSearchParams(document.location.search);
        const response = await checkAuthorised(baseUrl, params);
        if (response.is_authorised) {
          setShowCancel(false);
          setIsComplete(true);
          dispatchCompleted();
          clearTimeout(timeout);
        } else {
          timeout = setTimeout(doCheck, CHECK_INTERVAL_MS);
        }
      } catch (e) {
        console.error(e);
        timeout = setTimeout(doCheck, CHECK_INTERVAL_MS);
      }
    };

    doCheck();

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <div className="flex flex-col justify-center items-center animate-slideinalt my-8 px-4">
      <Progress complete={isComplete} />
      {!isComplete && (
        <>
          <h3 className="mt-8 mb-1 font-semibold">
            {localization.languageCode === "nb"
              ? "Fortsett i nettleseren din"
              : "Fortsätt i din webbläsare"}
          </h3>
          <p className="my-1 text-center">
            {localization.languageCode === "nb"
              ? "Fortsett å opprette forbindelse i nettleseren din. Gå deretter tilbake hit for å fullføre."
              : "Fortsätt att skapa koppling i din webbläsare. Återvänd sedan hit för att fullborda."}
          </p>
          {showCancel && (
            <button
              onClick={onCancel}
              className="bg-transparent active:scale-95 text-gray-400 font-medium rounded-full px-4 py-2 my-6 transition-all"
            >
              Avbryt
            </button>
          )}
        </>
      )}
    </div>
  );
};
