export default{
  // General
  'About {appName}': 'Apie {appName}',
  'About Enable Banking API': 'Apie Enable Banking API',
  'Application description is not provided.': 'Programos aprašymas nepateiktas.',
  'Authenticate': 'Autentifikuoti',
  'Authentication method': 'Autentifikavimo metodas',
  'Authentication session has expired': 'Autentifikavimo sesija baigėsi',
  'Cancel': 'Atšaukti',
  'Click {0} button if you have confirmed authorization in the BankID app.': 'Paspauskite {0} mygtuką jei patvirtinote autorizaciją BankID aplikacijoje.',
  'Continue authentication': 'Tęsti autentifikavimą',
  'Enter your OTP code with index {0}': 'Įvesti OTP kodą su indeksu {0}',
  'Error during authentication:': 'Autentifikavimo klaida:',
  'Error while updating authentication status': 'Autentifikavimo statuso atnaujinimo klaida',
  'Open the BankID app on this device': 'Atidaryti BankID aplikaciją šiame įrenginyje',
  'OTP code': 'OTP kodas',
  'Redirecting to your account servicing provider': 'Nukreipiama į jūsų sąskaitos aptarnavimo teikėją',
  'Returning to the authentication initiator': 'Grįžtama pas autentifikavimo iniciatorių',
  'Start the BankID app on your mobile device and tap the QR code button in the app to scan the QR code on this page': 'Paleiskite BankID aplikaciją savo mobiliajame įrenginyje ir paspauskite QR kodo mygtuką aplikacijoje, jog nuskenuotumėte šio puslapio QR kodą',
  'or': 'ar',
  'Starting authorization': 'Pradedama autorizacija',
  'Start typing for filtering the list': 'Norėdami filtruoti sąrašą, pradėkite rašyti',
  'To continue please use your authentication app': 'Jog tęstumėte, naudokite autentifikavimo aplikaciją',
  'Waiting for completion of authentication': 'Laukiama autentifikavimo pabaigos',
  'Try again': 'Bandyti vėl',
  'Submit': 'Pateikti',
  'Unable to access server': 'Serveris nepasiekiamas',
  'Unable to authenticate': 'Autentifikacija negalima',
  'Unable to continue authorization': 'Negalima tęsti autentifikacijos',
  'Unable to continue decoupled authorization': 'Negalima tęsti atsietos autorizacijos',
  'Unable to handle authentication status "{status}"': 'Nepavyksta valdyti autentifikavimo statuso "{status}"',
  'Unknown error occurred': 'Nežinoma klaida',
  'You need to close this window': 'Uždarykite šį langą',
  // Consent
  'Authentication is initiated by {0}.': 'Autentifikavimas inicijuojamas {0}.',
  'After you complete authentication, {0} will be shared with the authentication initiator.': 'Atlikus autentifikavimą, {0} bus pasidalinta su autentifikavimo iniciatoriumi.',
  'your payment accounts data': 'jūsų mokėjimo sąskaitos duomenimis',
  'the list of your payment accounts': 'jūsų mokėjimo sąskaitų sąrašas',
  'the list of your payment accounts and accounts balances': 'jūsų mokėjimo sąskaitų ir sąskaitų likučių sąrašas',
  'the list of your payment accounts and accounts transactions': 'jūsų mokėjimo sąskaitų ir sąskaitų operacijų sąrašas',
  'the list of your payment accounts, accounts balances and transactions': 'jūsų mokėjimo sąskaitų, sąskaitų likučių ir operacijų sąrašas',
  'By pressing {0} button you agree with the {1}.': 'Paspausdami {0} mygtuką sutinkate su {1}.',
  'terms of Enable Banking API service': 'Enable Banking API paslaugų sąlygos',
  'Press {0} button if you are not willing to share your payment accounts data.': 'Paspauskite {0} mygtuką jei nesutinkate pasidalinti savo mokėjimų sąskaitos duomenimis.',
  // Payment consent
  'Payment details': 'Mokėjimo detalės',
  'Payment ID provided by the application: {0}': 'Programos pateiktas mokėjimo ID: {0}',
  'Account number where to transfer funds: {0}': 'Sąskaitos numeris, kur pervesti lėšas: {0}',
  'Payee name in the payment: {0}': 'Mokėjimo gavėjo vardas mokėjime: {0}',
  'Payee country: {0}': 'Mokėjimo gavėjo šalis: {0}',
  'Payment amount: {0}': 'Mokėjimo suma: {0}',
  'Message or reference number in the payment: {0}': 'Mokėjimo žinutė arba nuorodos numeris: {0}',
  'Payment is initiated by {0}.': 'Mokėjimą inicijuoja {0}.',
  'After you complete authentication, your payment consent will be requested by your account servicing payment service provider.': 'Kai baigsite autentifikavimą, jūsų sąskaitą aptarnaujantis mokėjimo paslaugų teikėjas paprašys jūsų mokėjimo sutikimo.',
  'terms of service': 'paslaugų teikimo sąlygos',
  'Press {0} button if you are not willing to complete this payment.': 'Jei nenorite atlikti šio mokėjimo, paspauskite mygtuką {0}.',
  'Select Payment Account': 'Pasirinkite mokėjimo sąskaita',
  'Confirm': 'Patvirtinti',
  // About Tilisy
  'Enable Banking API is an online service that enables you to use your payment account data from a chosen bank or a similar organization (Account Servicing Payment Service Provider, the ASPSP) in other digital services.': 'Enable Banking API yra internetinė paslauga, leidžianti savo mokėjimo sąskaitos duomenis iš pasirinkto banko ar panašios organizacijos naudoti kitose skaitmeninėse platformose.',
  'To use your payment account data, you must authenticate in the ASPSP service.': 'Jog naudoti mokėjimo sąskaitos duomenis, privaloma autentifikavimas ASPSP paslaugoje.',
  'The service was formerly known as Tilisy business API.': 'Ši paslauga anksčiau buvo žinoma kaip Tilisy business API.',
  'Your explicit consent to use the payment account data will be maintained in Enable Banking API.': 'Jūsų aiškus sutikimas naudoti mokėjimo sąskaitos duomenis bus išlaikomas Enable Banking API.',
  'This service registers your PSU authentication tokens and consent ID.': 'Ši paslauga registruoja jūsų PSU autentifikavimo žetonus ir sutikimo ID.',
  'Once you have given the consent in the ASPSP service Enable Banking API opens access to your payment account data so that the digital service you are using (i.e. the authentication initiator) can retrieve this data.': 'Gavus jūsų sutikimą ASPSP paslaugai Enable Banking API gaus prieigą prie jūsų mokėjimo sąskaitos duomenų ir suteiks juos skaitmeninei platformai (autentifikafimo iniciatoriui), kurią naudojate.',
  'Your payment account data flows through Enable Banking API and won\'t be registered there.': 'Jūsų mokėjimo sąskaitos duomenis pereis per Enable Banking API, jie nebus registruojami.',
  'Enable Banking API is owned and operated by Enable Banking Oy, located in Otakaari 5, 02150 Espoo, Finland, company ID 2988499-7.': 'Enable Banking API priklauso ir yra valdomas Enable Banking Oy, adresas Otakaari 5, 02150 Espoo, Suomija, kompanijos ID 2988499-7.',
  'The company is a registered service provider (Account Information Service Provider, AISP) and supervised by the Finnish Financial Supervisory Authority (the FIN-FSA).': 'Įmonė yra registruota paslaugų teikėja (Sąskaitos Informacijos Paslaugos Teikėjas (AISP)) prižiūrimas Suomijos Finansų Priežiūros Institucijos (FIN-FSA).',
  'The registration details can be found from {0}.': 'Registracijos duomenis gali būti rasti {0}.',
  'the FIN-FSA register for payment services providers': 'FIN-FSA mokėjimų paslaugų teikėjų registras',
  'Please read the {0} carefully before continuing to authentication.': 'Atidžiai perskaitykite {0} prieš tęsiant autentifikavimą.',
  'You can check the terms and additional information on Enable Banking API service anytime from {0}.': 'Galite patikrinti Enable Banking API sąlygas ir rasti papildomą informaciją bet kuriuo metu iš {0}.',
  'Information and updates on service or fraudulent or corresponding activity threatening the secure use of the service will be communicated to you by your digital service provider together with Enable Banking Oy.': 'Informaciją ir atnaujinimus apie paslaugą, apgaulingą ar atitinkamą veiklą, keliančią grėsmę saugiam paslaugos naudojimui, jums perduos jūsų skaitmeninių paslaugų teikėjas kartu su „Enable Banking Oy“.',
  // Terms of Service
  'Terms of Service': 'Paslaugų teikimo sąlygos',
  'Last updated:': 'Paskutinį kartą atnaujinta:',
  'Please read these terms of service carefully before using Enable Banking API.': 'Prieš naudojant Enable Banking API atidžiai perskaitykite šias paslaugų sąlygas.',
  'Description of the service': 'Paslaugos aprašymas',
  'Processing of personal data': 'Asmens duomenų apdorojimas',
  'Enable Banking processes personal data only for the purpose and to the extent that Enable Banking API service can function for you.': 'Enable Banking apdoroja ir tvarko asmens duomenis tik tiek kiek reikia, jog Enable Banking API paslauga atitiktų jūsų poreikius.',
  'When processing personal data Enable Banking API undertakes to comply with the legislation relating to processing of personal data and data protection as well as other legislation applicable and especially the EU General Data Protection Regulation (EU 2016/679).': 'Apdorojant ir tvarkant asmens duomenis Enable Banking API įsipareigoja laikytis teisės aktų, susijusių su asmens duomenų tvarkymu ir duomenų apsaugos, taip pat kitų taikomų teisės aktų ir ypač ES Bendrojo duomenų apsaugos reglamento (ES 2016/679).',
  'The more detailed contents of the personal data processing, including:': 'Išsamesnis turinys dėl asmens duomenų tvarkymo, įskaitant:',
  '(a) the nature and purpose of the processing;': '(a) pobūdis ir tikslas tvarkyti;',
  '(b) the type of personal data and categories of data subjects;': '(b) asmens duomenų ir duomenų subjektų kategorijų tipas;',
  '(c) the applicable data protection measures;': '(c) taikomos duomenų apsaugos priemonės;',
  '(d) the customer’s rights and responsibilities;': '(d) kliento teisės ir atsakomybės;',
  'and (e) the object and duration of the personal data processing shall be described in greater detail in the Privacy Policy.': 'ir (e) asmens duomenų tvarkymo objektas ir trukmė turi būti aprašyta išsamiau Privatumo Politikoje.',
  'Links to other websites': 'Nuorodos į kitas svetaines',
  'Enable Banking may contain links or redirect you to third-party web sites or services that are not owned or controlled by the Company, including but not limited to ASPSPs’ web sites or services.': 'Enable Banking gali būti nuorodų arba nukreipimų į trečiųjų šalių interneto svetaines ar paslaugas, kurios nuosavybės teisės nepriklauso ir nėra kontroliuojamos Įmonės, įskaitant, bet neapsiribojant ASPSPs tinklalapiais ar paslaugomis.',
  'The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services.': 'Įmonė nekontroliuoja, ir neprisiima jokios atsakomybės už trečiųjų šalių svetainių ar paslaugų turinį, privatumo politiką, ar praktikas.',
  'You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.': 'Jūs taip pat pripažįstate ir sutinkate, kad Bendrovė negali būti atsakinga ir atskaitinga, tiesiogiai ar netiesiogiai, už bet kokią žalą ar nuostolius, patirtus, ar tariamai patirtus, dėl ryšio su trečiųjų šalių svetainėmis ar paslaugų turiniu, privatumo politika.',
  'We strongly advise you to read terms and conditions and privacy policies of any third-party web sites or services that you visit.': 'Mes primygtinai rekomenduojame jums perskaityti bet kurios trečiosios šalies svetainių ar paslaugų sąlygas ir privatumo politiką.',
  'No warranty': 'Be garantijos',
  'Without limiting the foregoing, neither the Company nor any of the company\'s provider makes any representation or warranty of any kind, express or implied:': 'Be pirmiau išdėstytų nuostatų, nei Bendrovė, nei bet įmonės teikėjas daro jokios rūšies aiškios ar numanomos garantijos:',
  '(i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon;': '(i) dėl paslaugų veikimo ir prieinamumo, informacijos, turinio ir medžiagų ar įtrauktų produktų.',
  '(ii) that the Service will be uninterrupted or error-free;': '(ii) kad paslauga bus nepertraukiama ir be klaidų;',
  '(iii) as to the accuracy, reliability, or currency of any information or content provided through the Service.': '(iii) dėl tikslumo, patikimumo, arba bet kokios informacijos ar turinio valiutos teikiamos per paslaugą.',
  'Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.': 'Kai kurios jurisdikcijos neleidžia tam tikrų tipų garantijų ar apribojimų, taikomų įstatymų vartotojų teisėms atskirties, todėl kai kurios arba visos pirmiau minėtos išimtys ir apribojimai, gali būti jums netaikomi.',
  'But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.': 'Bet tokiu atveju, išdėstytos šioje skiltyje išimtys ir apribojimai taikomi didesniu mastu įgyvendinami pagal taikytinus įstatymus.',
  'Changes': 'Pakeitimai',
  'The Company reserves the right to modify or replace the Service at any time.': 'Bendrovė pasilieka teisę keisti ar pakeisti paslaugą kita bet kuriuo metu.',
  'If a revision is material the Company will make reasonable efforts to notify you prior to any new terms taking effect together with other digital service providers.': 'Jei revizija yra esminė Bendrovė dės visas pastangas, kad jums būtų pranešama prieš bet kokius naujus terminus, kurie įsigalios  kartu su kitais skaitmeninių paslaugų teikėjais.',
  'You can also check any updates from this page.': 'Taip pat galite patikrinti visus atnaujinimus šiame puslapyje.',
  'By continuing to access or use the Service after those revisions become effective, you agree to be bound by the revised terms.': 'Tęsiant naudojimąsi Paslauga po revizijos tapimo veiksminga, jūs sutinkate su patikslintomis sąlygomis.',
  'If you do not agree to the new terms, in whole or in part, please stop using the Service.': 'Jei nesutinkate su naujomis sąlygomis, visiškai arba iš dalies, prašome nebesinaudoti paslauga.',
  'Termination': 'Nutraukimas',
  'If for any reason, you no longer wish to use the Service to share your payment accounts data with other digital services, please {0}.': 'Jei dėl bet kokios priežasties nebete norite naudotis Paslauga, kad pasidalintumėte savo mokėjimo sąskaitų duomenimis su kitomis skaitmeninėmis paslaugomis, prašome {0}.',
  'visit the Data Sharing Consents page': 'aplankykite Duomenų Bendrinimo Sutikimo puslapį',
  'Upon termination, your right to use the Service will cease immediately.': 'Nutraukus naudojimąsį, jūsų teisė naudotis paslauga bus nedelsiant nutraukta.',
  'We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever.': 'Mes gali nutraukti arba sustabdyti prieigą iš karto, be išankstinio įspėjimo ar įsipareigojimo, dėl bet kokios priežasties.',
  'Governing law': 'Reglamentuojantys teisės aktai',
  'The laws of Finland shall govern your use of the Service.': 'Suomijos įstatymai reglamentuoja jūsų naudojimąsi Paslauga.',
  'If you are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.': 'Jei esate Europos Sąjungos vartotojams, jums bus taikomi bet kurie privalomi nuostatai ir teisės šalies, kurioje gyvenate.',
  'Disputes resolution': 'Ginčų sprendimas',
  'If you have any concern or dispute about the Service, at first, you are asked for trying to resolve the dispute informally by contacting the Company.': 'Jei turite kokių nors susirūpinimų ar nusiskundimų dėl paslaugos, pirmiausia, prašome bandyti išspręsti ginčą neoficialiai, susisiekus su įmone.',
  'The consumer users in Finland are also entitled to be in contact with Consumer Disputes Board, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tel. +358 29 566 5200, {0}.': 'Vartotojai Suomijoje taip pat turi teisę susisiekti su vartotojų ginčų valdyba Hämeentie 3, P.O. 306 Box 00531 Helsinkis, Tel. +358 29 566 5200, {0}.',
  'The company related issues shall be directed to the supervising authority.': 'Su įmone susiję klausimai turi būti nukreipti į prižiūrinčią instituciją.',
  'The FIN-FSA contact information is: Snellmaninkatu 6, 00100 Helsinki, Finland, tel. +358 9 183 51 (switchboard); www.finanssivalvonta.fi.': 'FIN-FSA kontaktinė informacija: Snellmaninkatu 6, 00100 Helsinkis, Suomija, Tel. 358 9 183 51 (skirstomasis); www.finanssivalvonta.fi.',
  'Contact us': 'Susisiekite su mumis',
  'If you have any questions about this terms of service, you can contact us by email at {0}.': 'Jei turite kokių nors klausimų apie šias paslaugų teikimo sąlygas, galite susisiekti su mumis elektroniniu paštu {0}.',
  // Backend messages
  '10 digit client number': '10 skaitmenų kliento numeris',
  '10 digit organisation number (or 12 digit personal number if the company is a sole proprietorship)': '10 skaitmenų organizacijos numeris (arba 12 skaitmenų asmens numeris, jei įmonė yra individuali įmonė)',
  '10 digit organization number.': '10 skaitmenų organizacijos numeris.',
  '17 digit card number (starting with 6703)': '17 skaitmenų kortelės numeris (pradedant 6703)',
  '6 digit Handelsbanken customer number': '6 skaitmenų Handelsbanken kliento numeris',
  '8 digits CVR number': '8 skaitmenų CVR numeris',
  '9 digits Organisasjonsnummer': '9 skaitmenų Organisasjonsnummer',
  'Agreement number': 'Sutarties numeris',
  'Agreement number for Nordea Corporate Netbank': 'Nordea korporatyvinio internetinio banko sutarties numeris',
  'Bank user ID': 'Banko vartotojo ID',
  'Business identity code (Y-tunnus) in 1234567-8 format': 'Įmonės tapatybės kodas (Y-tunnus) 1234567-8 formatu',
  'Business identity code which is displayed in the Corporate Internet bank settings': 'Įmonės tapatybės kodas, kuris rodomas Įmonės interneto banko nustatymuose',
  'Client ID': 'Kliento ID',
  'Client number': 'Kliento numeris',
  'Company ID': 'Įmonės ID',
  'Company number': 'Įmonės numeris',
  'Corporate ID': 'Įmonės ID',
  'Corporate registration ID': 'Įmonės registracijos ID',
  'Currency code': 'Valiutos kodas',
  'Digit card number': 'Skaitmeninis kortelės numeris',
  'Disposer': 'Šalintuvas',
  'E-mail': 'El. paštas',
  'E-mail used for authenticating to Holvi': 'El. paštas naudojamas Holvi autentifikavimui',
  'Either the 14 digit SEB identifier for the company or the 10 digit organization number': 'Arba 14 skaitmenų įmonės SEB identifikatorius arba 10 skaitmenų organizacijos numeris',
  'FKDN (without subaccount number): 3 digit branch number + 7 digit customer number': 'FKDN (be subsąskaitos numerio): 3 skaitmenų filialo numeris + 7 skaitmenų kliento numeris',
  'For Swedish Corporates, this is their Organisation number or SHB number, and for Sole Traders (Enskild firma), it is their Personal number, all of which are 10 digits.': 'Švedijos įmonėms tai yra organizacijos numeris arba SHB numeris, o individualiems prekiautojams (Enskild firma) – asmeninis numeris, kurį sudaro 10 skaitmenų.',
  'IBAN': 'IBAN',
  'ID, NIE, Passport': 'ID, NIE, pasas',
  'Login ID used in MyBRD aplications': 'MyBRD programose naudojamas prisijungimo ID',
  'Login Name': 'Prisijungimo vardas',
  'Mandate ID': 'Mandato ID',
  'Norwegian national identity number in the format DDMMYYXXXXX (11 digits)': 'Norvegijos nacionalinis asmens kodas DDMMYYXXXXX formatu (11 skaitmenų)',
  'Online banking PIN': 'Internetinės bankininkystės PIN kodas',
  'Online banking Password': 'Internetinės bankininkystės slaptažodis',
  'Organisation ID in the format TBxxxxx or TXxxxxx': 'Organizacijos ID formatu TBxxxxx arba TXxxxxx',
  'Organisation number': 'Organizacijos numeris',
  'PIN': 'PIN kodas',
  'Password': 'Slaptažodis',
  'Password for Aktia Internet bank': 'Aktia interneto banko slaptažodis',
  'Password used for authenticating to Holvi': 'Slaptažodis, naudojamas autentifikuoti Holvi',
  'Personal ID': 'Asmens ID',
  'Personal ID number': 'Asmens ID numeris',
  'Personal code': 'Asmens kodas',
  'Personal code of the user': 'Vartotojo asmeninis kodas',
  'Personal signatory number': 'Asmeninis parašo numeris',
  'Personal username for online banking': 'Asmeninis prisijungimo vardas internetinei bankininkystei',
  'Postbank ID': 'Pašto banko ID',
  'Social security number (Personnummer)': 'Socialinio draudimo numeris (Personnummer)',
  'Social security number in the format YYYYMMDDXXXX': 'Socialinio draudimo numeris formatu YYYYMMDDXXXX',
  'Swedish social security number in the format YYYYMMDDXXXX': 'Švedijos socialinio draudimo numeris YYYYMMDDXXXX formatu',
  'The IBAN of the user authenticating': 'Autentifikuojančio vartotojo IBAN',
  'The currency code': 'Valiutos kodas',
  'The user ID of the user authenticating': 'Autentifikuojančio vartotojo ID',
  'Unique personal ID number': 'Unikalus asmens kodas',
  'User ID': 'Vartotojo ID',
  'User ID for Aktia Internet bank': 'Aktia interneto banko vartotojo ID',
  'User ID for Nordea Corporate Netbank': 'Nordea Corporate Internetbank vartotojo ID',
  'User ID for online banking': 'Internetinės bankininkystės vartotojo ID',
  'User ID in the format XX000000': 'Vartotojo ID formatu XX000000',
  'User ID used to log in to the bank': 'Vartotojo ID, naudojamas prisijungiant prie banko',
  'Username': 'Vartotojo vardas',
  'Username used to log in to the bank': 'Vartotojo vardas, naudojamas prisijungiant prie banko',
  'VR NetKey': 'VR NetKey',
  'WebSSO user name': 'WebSSO vartotojo vardas'
}
