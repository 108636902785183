export default {
  // General
  'About {appName}': 'About {appName}',
  'About Enable Banking API': 'About Enable Banking API',
  'Application description is not provided.': 'Application description is not provided.',
  'Authenticate': 'Authenticate',
  'Authentication method': 'Authentication method',
  'Authentication session has expired': 'Authentication session has expired',
  'Cancel': 'Cancel',
  'Click {0} button if you have confirmed authorization in the BankID app.': 'Click {0} button if you have confirmed authorization in the BankID app.',
  'Continue authentication': 'Continue authentication',
  'Enter your OTP code with index {0}': 'Enter your OTP code with index {0}',
  'Error during authentication:': 'Error during authentication:',
  'Error while updating authentication status': 'Error while updating authentication status',
  'Open the BankID app on this device': 'Open the BankID app on this device',
  'Redirecting to your account servicing provider': 'Redirecting to your account servicing provider',
  'Returning to the authentication initiator': 'Returning to the authentication initiator',
  'Start the BankID app on your mobile device and tap the QR code button in the app to scan the QR code on this page': 'Start the BankID app on your mobile device and tap the QR code button in the app to scan the QR code on this page',
  'or': 'or',
  'Starting authorization': 'Starting authorization',
  'Start typing for filtering the list': 'Start typing for filtering the list',
  'To continue please use your authentication app': 'To continue please use your authentication app',
  'Waiting for completion of authentication': 'Waiting for completion of authentication',
  'Try again': 'Try again',
  'Submit OTP code': 'Submit OTP code',
  'Unable to access server': 'Unable to access server',
  'Unable to authenticate': 'Unable to authenticate',
  'Unable to continue authorization': 'Unable to continue authorization',
  'Unable to continue decoupled authorization': 'Unable to continue decoupled authorization',
  'Unable to handle authentication status "{status}"': 'Unable to handle authentication status "{status}"',
  'Unknown error occurred': 'Unknown error occurred',
  'You need to close this window': 'You need to close this window',
  // Consent
  'Authentication is initiated by {0}.': 'Authentication is initiated by {0}.',
  'After you complete authentication, {0} will be shared with the authentication initiator.': 'After you complete authentication, {0} will be shared with the authentication initiator.',
  'your payment accounts data': 'your payment accounts data',
  'the list of your payment accounts': 'the list of your payment accounts',
  'the list of your payment accounts and accounts balances': 'the list of your payment accounts and accounts balances',
  'the list of your payment accounts and accounts transactions': 'the list of your payment accounts and accounts transactions',
  'the list of your payment accounts, accounts balances and transactions': 'the list of your payment accounts, accounts balances and transactions',
  'By pressing {0} button you agree with the {1}.': 'By pressing {0} button you agree with the {1}.',
  'terms of Enable Banking API service': 'terms of Enable Banking API service',
  'Press {0} button if you are not willing to share your payment accounts data.': 'Press {0} button if you are not willing to share your payment accounts data.',
  // Payment consent
  'Payment details': 'Payment details',
  'Payment ID provided by the application: {0}': 'Payment ID provided by the application: {0}',
  'Account number where to transfer funds: {0}': 'Account number where to transfer funds: {0}',
  'Payee name in the payment: {0}': 'Payee name in the payment: {0}',
  'Payee country: {0}': 'Payee country: {0}',
  'Payment amount: {0}': 'Payment amount: {0}',
  'Message or reference number in the payment: {0}': 'Message or reference number in the payment: {0}',
  'Payment is initiated by {0}.': 'Payment is initiated by {0}.',
  'After you complete authentication, your payment consent will be requested by your account servicing payment service provider.': 'After you complete authentication, your payment consent will be requested by your account servicing payment service provider.',
  'terms of service': 'terms of service',
  'Press {0} button if you are not willing to complete this payment.': 'Press {0} button if you are not willing to complete this payment.',
  'Select Payment Account': 'Select Payment Account',
  'Confirm': 'Confirm',
  // About Tilisy
  'Enable Banking API is an online service that enables you to use your payment account data from a chosen bank or a similar organization (Account Servicing Payment Service Provider, the ASPSP) in other digital services.': 'Enable Banking API is an online service that enables you to use your payment account data from a chosen bank or a similar organization (Account Servicing Payment Service Provider, the ASPSP) in other digital services.',
  'To use your payment account data, you must authenticate in the ASPSP service.': 'To use your payment account data, you must authenticate in the ASPSP service.',
  'The service was formerly known as Tilisy business API.': 'The service was formerly known as Tilisy business API.',
  'Your explicit consent to use the payment account data will be maintained in Enable Banking API.': 'Your explicit consent to use the payment account data will be maintained in Enable Banking API.',
  'This service registers your PSU authentication tokens and consent ID.': 'This service registers your PSU authentication tokens and consent ID.',
  'Once you have given the consent in the ASPSP service Enable Banking API opens access to your payment account data so that the digital service you are using (i.e. the authentication initiator) can retrieve this data.': 'Once you have given the consent in the ASPSP service Enable Banking API opens access to your payment account data so that the digital service you are using (i.e. the authentication initiator) can retrieve this data.',
  'Your payment account data flows through Enable Banking API and won\'t be registered there.': 'Your payment account data flows through Enable Banking API and won\'t be registered there.',
  'Enable Banking API is owned and operated by Enable Banking Oy, located in Otakaari 5, 02150 Espoo, Finland, company ID 2988499-7.': 'Enable Banking API is owned and operated by Enable Banking Oy, located in Otakaari 5, 02150 Espoo, Finland, company ID 2988499-7.',
  'The company is a registered service provider (Account Information Service Provider, AISP) and supervised by the Finnish Financial Supervisory Authority (the FIN-FSA).': 'The company is a registered service provider (Account Information Service Provider, AISP) and supervised by the Finnish Financial Supervisory Authority (the FIN-FSA).',
  'The registration details can be found from {0}.': 'The registration details can be found from {0}.',
  'the FIN-FSA register for payment services providers': 'the FIN-FSA register for payment services providers',
  'Please read the {0} carefully before continuing to authentication.': 'Please read the {0} carefully before continuing to authentication.',
  'You can check the terms and additional information on Enable Banking API service anytime from {0}.': 'You can check the terms and additional information on Enable Banking API service anytime from {0}.',
  'Information and updates on service or fraudulent or corresponding activity threatening the secure use of the service will be communicated to you by your digital service provider together with Enable Banking Oy.': 'Information and updates on service or fraudulent or corresponding activity threatening the secure use of the service will be communicated to you by your digital service provider together with Enable Banking Oy.',
  // Terms of Service
  'Terms of Service': 'Terms of Service',
  'Last updated:': 'Last updated:',
  'Please read these terms of service carefully before using Enable Banking API.': 'Please read these terms of service carefully before using Enable Banking API.',
  'Description of the service': 'Description of the service',
  'Processing of personal data': 'Processing of personal data',
  'Enable Banking processes personal data only for the purpose and to the extent that Enable Banking API service can function for you.': 'Enable Banking processes personal data only for the purpose and to the extent that Enable Banking API service can function for you.',
  'When processing personal data Enable Banking API undertakes to comply with the legislation relating to processing of personal data and data protection as well as other legislation applicable and especially the EU General Data Protection Regulation (EU 2016/679).': 'When processing personal data Enable Banking API undertakes to comply with the legislation relating to processing of personal data and data protection as well as other legislation applicable and especially the EU General Data Protection Regulation (EU 2016/679).',
  'The more detailed contents of the personal data processing, including:': 'The more detailed contents of the personal data processing, including:',
  '(a) the nature and purpose of the processing;': '(a) the nature and purpose of the processing;',
  '(b) the type of personal data and categories of data subjects;': '(b) the type of personal data and categories of data subjects;',
  '(c) the applicable data protection measures;': '(c) the applicable data protection measures;',
  '(d) the customer’s rights and responsibilities;': '(d) the customer’s rights and responsibilities;',
  'and (e) the object and duration of the personal data processing shall be described in greater detail in the Privacy Policy.': 'and (e) the object and duration of the personal data processing shall be described in greater detail in the Privacy Policy.',
  'Links to other websites': 'Links to other websites',
  'Enable Banking may contain links or redirect you to third-party web sites or services that are not owned or controlled by the Company, including but not limited to ASPSPs’ web sites or services.': 'Enable Banking may contain links or redirect you to third-party web sites or services that are not owned or controlled by the Company, including but not limited to ASPSPs’ web sites or services.',
  'The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services.': 'The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services.',
  'You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.': 'You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.',
  'We strongly advise you to read terms and conditions and privacy policies of any third-party web sites or services that you visit.': 'We strongly advise you to read terms and conditions and privacy policies of any third-party web sites or services that you visit.',
  'No warranty': 'No warranty',
  'Without limiting the foregoing, neither the Company nor any of the company\'s provider makes any representation or warranty of any kind, express or implied:': 'Without limiting the foregoing, neither the Company nor any of the company\'s provider makes any representation or warranty of any kind, express or implied:',
  '(i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon;': '(i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon;',
  '(ii) that the Service will be uninterrupted or error-free;': '(ii) that the Service will be uninterrupted or error-free;',
  '(iii) as to the accuracy, reliability, or currency of any information or content provided through the Service.': '(iii) as to the accuracy, reliability, or currency of any information or content provided through the Service.',
  'Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.': 'Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you.',
  'But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.': 'But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.',
  'Changes': 'Changes',
  'The Company reserves the right to modify or replace the Service at any time.': 'The Company reserves the right to modify or replace the Service at any time.',
  'If a revision is material the Company will make reasonable efforts to notify you prior to any new terms taking effect together with other digital service providers.': 'If a revision is material the Company will make reasonable efforts to notify you prior to any new terms taking effect together with other digital service providers.',
  'You can also check any updates from this page.': 'You can also check any updates from this page.',
  'By continuing to access or use the Service after those revisions become effective, you agree to be bound by the revised terms.': 'By continuing to access or use the Service after those revisions become effective, you agree to be bound by the revised terms.',
  'If you do not agree to the new terms, in whole or in part, please stop using the Service.': 'If you do not agree to the new terms, in whole or in part, please stop using the Service.',
  'Termination': 'Termination',
  'If for any reason, you no longer wish to use the Service or share your data with other digital service, please contact us.': 'If for any reason, you no longer wish to use the Service or share your data with other digital service, please contact us.',
  'Upon termination, your right to use the Service will cease immediately.': 'Upon termination, your right to use the Service will cease immediately.',
  'We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever.': 'We may terminate or suspend your access immediately, without prior notice or liability, for any reason whatsoever.',
  'Governing law': 'Governing law',
  'The laws of Finland shall govern your use of the Service.': 'The laws of Finland shall govern your use of the Service.',
  'If you are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.': 'If you are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.',
  'Disputes resolution': 'Disputes resolution',
  'If you have any concern or dispute about the Service, at first, you are asked for trying to resolve the dispute informally by contacting the Company.': 'If you have any concern or dispute about the Service, at first, you are asked for trying to resolve the dispute informally by contacting the Company.',
  'The consumer users in Finland are also entitled to be in contact with Consumer Disputes Board, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tel. +358 29 566 5200, {0}.': 'The consumer users in Finland are also entitled to be in contact with Consumer Disputes Board, Hämeentie 3, P.O. Box 306, 00531 Helsinki, tel. +358 29 566 5200, {0}.',
  'The company related issues shall be directed to the supervising authority.': 'The company related issues shall be directed to the supervising authority.',
  'The FIN-FSA contact information is: Snellmaninkatu 6, 00100 Helsinki, Finland, tel. +358 9 183 51 (switchboard); www.finanssivalvonta.fi.': 'The FIN-FSA contact information is: Snellmaninkatu 6, 00100 Helsinki, Finland, tel. +358 9 183 51 (switchboard); www.finanssivalvonta.fi.',
  'Contact us': 'Contact us',
  'If you have any questions about this terms of service, you can contact us by email at {0}.': 'If you have any questions about this terms of service, you can contact us by email at {0}.',
  'Payments total amount: {0}': 'Payments total amount: {0}',
  'Number of payments: {0}': 'Number of payments: {0}'
}
