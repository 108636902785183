<template>
  <div class="enablebanking-pis loading-content">
    <div class="enablebanking-status text-center">
      <Spinner v-if="loading" class="enablebanking-status-spinner mx-auto" />
      <div class="enablebanking-status-info text-dark">{{ message }}</div>
      <div class="enablebanking-status-error text-danger">{{ error }}</div>
    </div>
    <div class="row justify-content-center" v-if="status === 'DATA_SHARING_CONSENT'">
      <div class="col-12 col-md-8 col-lg-6">
        <p class="text-center">
          <i18n-t tag="strong" keypath="Payment details"></i18n-t>
        </p>
        <div v-if="paymentsCount === 1">
          <i18n-t tag="p" keypath="Payee account: {0}">
            <strong>{{ paymentInfos[0].creditor_account.identification }}</strong>
          </i18n-t>
          <i18n-t tag="p" keypath="Payee name: {0}">
            <strong>{{ paymentInfos[0].creditor_name }}</strong>
          </i18n-t>
          <i18n-t tag="p" keypath="Payee country: {0}" v-if="paymentInfos[0].creditor_country">
            <strong>{{ paymentInfos[0].creditor_country }}</strong>
          </i18n-t>
          <i18n-t tag="p" keypath="Payment amount: {0}">
            <strong>{{ paymentInfos[0].amount }} {{ paymentInfos[0].currency }}</strong>
          </i18n-t>
          <i18n-t v-if="paymentInfos[0].message" tag="p" keypath="Message or reference number: {0}">
            <strong>{{ paymentInfos[0].message }}</strong>
          </i18n-t>
          <i18n-t v-if="paymentInfos[0].end_to_end_identification" tag="p" keypath="Payment ID: {0}">
            <strong>{{ paymentInfos[0].end_to_end_identification }}</strong>
          </i18n-t>
        </div>
        <div v-if="paymentsCount > 1">
          <i18n-t tag="p" keypath="Number of payment transactions: {0}">
            <strong>{{ paymentsCount }}</strong>
          </i18n-t>
          <i18n-t tag="p" keypath="Total amount to be paid: {0}">
            <strong>{{ totalAmount }}</strong>
          </i18n-t>
          <div v-if="!showPaymentDetails">
            <button class="btn btn-link px-0 py-0" v-on:click="togglePaymentsDescription">
              {{ $t("Show payment transactions") }}
            </button>
          </div>
          <div v-if="showPaymentDetails">
            <table class="text-center">
              <thead>
                <tr>
                  <th>{{ $t("Payee account") }}</th>
                  <th>{{ $t("Payee name") }}</th>
                  <th v-if="paymentInfos.some(e => e.creditor_country)">
                    {{ $t("Payee country") }}
                  </th>
                  <th>{{ $t("Payment amount") }}</th>
                  <th>{{ $t("Message or reference number") }}</th>
                  <th v-if="paymentInfos.some(e => e.end_to_end_identification)">
                    {{ $t("Payment ID") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="payment in paymentInfos" :key="payment.end_to_end_identification">
                  <td>{{ payment.creditor_account.identification }}</td>
                  <td>{{ payment.creditor_name }}</td>
                  <td v-if="paymentInfos.some(e => e.creditor_country)">
                    {{ payment.creditor_country }}
                  </td>
                  <td>{{ payment.amount }} {{ payment.currency }}</td>
                  <td>{{ payment.message }}</td>
                  <td v-if="paymentInfos.some(e => e.end_to_end_identification)">
                    {{ payment.end_to_end_identification }}
                  </td>
                </tr>
              </tbody>
            </table>
            <button class="btn btn-link px-0 py-0" v-on:click="togglePaymentsDescription">
              {{ $t("Hide payment transactions") }}
            </button>
          </div>
        </div>
        <p class="text-justify mt-3">
          <i18n-t tag="span" keypath="Payment is initiated by {0}." class="mr-1"><strong>{{ appName }}</strong></i18n-t>
          <i18n-t tag="span"
            keypath="After you complete authentication, your payment consent will be requested by your account servicing payment service provider."
            class="mr-1"></i18n-t>
        </p>
        <div class="form-inline justify-content-center">
          <button class="btn btn-theme" v-on:click="submitDataSharingConsent">
            {{ $t("Continue authentication") }}
          </button>
        </div>
      </div>
    </div>
    <auth-form :authOptions="authOptions" :error="error" v-if="status === 'AUTHENTICATION'"
      v-on:submit="submitCredentials" />
    <div class="enablebanking-account-selection row justify-content-center" v-if="status === 'ACCOUNT_SELECTION'">
      <div class="col-12 col-md-8 col-lg-6">
        <p class="enablebanking-account-selection-caption text-center mb-3">
          <i18n-t tag="strong" keypath="Please select an account to pay from"></i18n-t>
        </p>
        <form class="enablebanking-account-selection-form" v-on:submit.prevent="submitAccountSelection">
          <div class="form-group">
            <div v-for="acc in accountsList" :key="acc.resourceId">
              <input type="radio" class="enablebanking-account-selection-radio-input mr-1 mt-1 align-top" :value="acc.resourceId" :id="acc.resourceId"
                v-model="account" />
              <label :for="acc.resourceId">
                <p v-for="line in acc.data" :key='`account-${acc.resourceId}-${line}`' class="mb-0">{{ line }}</p>
              </label>
            </div>
          </div>
          <div class="form-inline justify-content-center">
            <button type="submit" class="enablebanking-account-selection-submit-button btn btn-theme" :disabled="!account">
              {{ $t("Select account") }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div v-if="!loading && !bankIdAppUri">
      <div v-if="authData.type == 'MESSAGE'">
          <p class="enablebanking-decouppled-text text-center"><strong>{{ authData.value }}</strong></p>
      </div>
      <div v-if="qrCodeUri" class="enablebanking-qrcode-container qrcode-container mb-4">
        <img id="qrCodeImage" class="enablebanking-qrcode-image qrcode-image" :src="qrCodeUri">
      </div>
      <div v-if="authData.type == 'OTP_CODE' && authDataRequired" >
        <p class="enablebanking-decouppled-text text-center"><strong>{{ authData.value }}</strong></p>
        <form class="enablebanking-otp-form form-inline justify-content-center">
          <input type="text" autocomplete="off" name="otpCode" class="enablebanking-otp-input form-control" required :placeholder="$t('OTP code')" v-model="otpCode">
          <button class="enablebanking-otp-submit-button btn btn-theme" v-on:click="submitOtpCode">{{ $t('Submit') }}</button>
        </form>
    </div>
    </div>
    <div v-if="status === 'PAYMENT_CONFIRMATION' || status === 'PAYMENT_AUTH'">
      <bank-id v-if="!loading && bankIdAppUri" :bankIdAppUri="bankIdAppUri" :displayOnly="displayOnly"
        v-on:BankIdAppRedirect="openBankIdApp" v-on:BankIdResume="onBankIdResume" v-on:PageHidden="stopUpdate" />
    </div>
    <div class="text-center" v-if="!loading && beforePaymentComfirmation">
      <button class="enablebanking-cancel-button btn btn-link" :disabled="cancelDisabled" v-on:click="cancelPayment">
        {{ $t("Cancel") }}
      </button>
    </div>
    <div v-if="status === 'DATA_SHARING_CONSENT'">
      <div class="row justify-content-center mt-5">
        <div class="col-12 col-md-8 col-lg-6 small text-muted">
          <p>
            <strong>{{ $t("About {appName}", { appName: appName }) }}</strong>
          </p>
          <p class="text-justify">{{ appDescriptionLocal }}</p>
          <about-service />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AboutService from '../components/AboutService.vue'
import AuthForm from '../components/AuthForm.vue'
import BankId from '../components/BankId.vue'
import BaseService from '../services/BaseService.js'
import Spinner from '../components/elements/Spinner.vue'

// const REQUEST_LIMIT = 300

export default {
  name: 'PIS',
  props: {
    widgetEnabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AboutService,
    AuthForm,
    BankId,
    Spinner
  },
  data () {
    return {
      loading: true,
      message: '',
      error: '',
      timeoutId: 0,
      prevStatus: null,
      status: null,
      authOptions: [],
      authData: '',
      authDataRequired: false,
      otpCode: '',
      accounts: [],
      account: null,
      cancelDisabled: false,
      paymentInfos: null,
      appName: '',
      bankIdAppUri: '',
      displayOnly: null,
      qrCodeUri: '',
      totalAmount: '',
      paymentsCount: 0,
      showPaymentDetails: false
    }
  },
  computed: {
    appDescriptionLocal: function () {
      if (
        typeof this.appDescription === 'string' &&
        this.appDescription.length > 0
      ) {
        return this.appDescription
      } else if (
        this.appDescription &&
        typeof this.appDescription[this.$i18n.locale] === 'string' &&
        this.appDescription[this.$i18n.locale].length > 0
      ) {
        return this.appDescription[this.$i18n.locale]
      } else {
        return this.$t('Application description is not provided.')
      }
    },
    accountsList: function () {
      if (!this.accounts) {
        return []
      }
      return this.accounts.map((a) => {
        const acc = {
          resourceId: a.resource_id,
          data: []
        }
        let accountId
        if (a.account_id.iban) {
          accountId = `IBAN ${a.account_id.iban}`
        } else if (a.account_id.other) {
          accountId = `${a.account_id.other.scheme_name} ${a.account_id.other.identification}`
        }
        if (a.currency !== 'XXX') {
          accountId += ` (${a.currency})`
        }
        acc.data.push(accountId)
        if (a.details) {
          acc.data.push(a.details)
        }
        if (a.product) {
          acc.data.push(a.product)
        }
        if (a.name) {
          acc.data.push(a.name)
        }
        return acc
      })
    },
    beforePaymentComfirmation: function () {
      return this.status === 'DATA_SHARING_CONSENT' || this.status === 'AUTHENTICATION' || this.status === 'ACCOUNT_LIST' || this.status === 'ACCOUNT_SELECTION' || this.status === 'PAYMENT_INITIATION' || this.status === 'PAYMENT_AUTH' || this.status === 'PAYMENT_BANK_UI'
    }
  },
  methods: {
    getHandler (status) {
      switch (status) {
        case 'DATA_SHARING_CONSENT':
          return this.handleDataSharingConsent
        case 'AUTHENTICATION':
          return this.handleAuthentication
        case 'ACCOUNT_LIST':
          return this.handleAccountList
        case 'ACCOUNT_SELECTION':
          return this.handleAccountSelection
        case 'PAYMENT_INITIATION':
          return this.handlePaymentInitiation
        case 'PAYMENT_BANK_UI':
          return this.confirmPayment
        case 'PAYMENT_AUTH':
          return this.confirmPayment
        case 'PAYMENT_CONFIRMATION':
          return this.confirmPayment
        case 'DONE':
          return this.handleDone
        case 'INVALID':
          return this.handleDone
        case 'REJECTED':
          return this.handleDone
        case 'CANCELLED':
          return this.handleDone
      }
    },
    isFinalStatus () {
      return [
        'DONE',
        'INVALID',
        'REJECTED',
        'CANCELLED'
      ].includes(this.status)
    },
    redirect (redirectUrl) {
      this.loading = true
      window.location.href = redirectUrl
    },
    displayQrCodeIfNecessary (data) {
      // FIXME: This is copied as is from the AIS code, probably doesn't need to be so complex
      // as QR codes are not changing dynamically. To changed at the same time with AIS.
      if (data.response.redirect_url) { // PUSH_OTP (see SDK issues #1277)
        const url = new URL(data.response.redirect_url)
        this.qrCodeUri = url.href
        this.$nextTick(() => {
          const qrCodeImage = document.getElementById('qrCodeImage')
          if (qrCodeImage === null) {
            // the element is not available
            return
          }
          qrCodeImage.style.display = 'block'
        })
      }
    },
    async updateStatus () {
      try {
        this.timeoutId = 0
        this.error = ''
        const r = await BaseService.getPayment()
        this.prevStatus = this.status
        this.status = r.data.response.status
        if (r.data.response.auth_data && r.data.response.auth_data.length) {
          this.authData = r.data.response.auth_data[0]
          if (['OTP_CODE'].includes(this.authData.type)) {
            this.authDataRequired = true
            this.otpCode = ''
            this.displayQrCodeIfNecessary(r.data)
            this.loading = false
            return
          }
        }
        if (this.status) {
          const handler = this.getHandler(this.status)
          handler(r.data)
          return
        }
        this.error = r.data.response.error
      } catch (error) {
        this.error = error.message
      }
    },
    scheduleUpdate (timeout, loading = true) {
      if (this.timeoutId !== 0) {
        // We don't want to schedule new update if there is already update scheduled
        return
      }
      this.loading = loading
      this.timeoutId = setTimeout(this.updateStatus, timeout)
    },
    async handleAuthentication (data) {
      this.loading = false
      this.authOptions = data.response.auth_info
      if (data.error && data.error.message) {
        this.error =
          this.$t('Error during authentication:') +
          ' ' +
          this.$t(data.error.message)
      }
    },
    calculateTotalAmount (paymentInfos) {
      // Transactions may be of different currencies, so we need to calculate total amount per currency
      // We also need to display amount with the max number of decimal places for each currency
      const amounts = {}
      for (const payment of paymentInfos) {
        if (!amounts[payment.currency]) {
          amounts[payment.currency] = {
            amount: 0,
            decimals: 0
          }
        }
        amounts[payment.currency].amount += parseFloat(payment.amount)
        let decimals = 0
        if (payment.amount % 1 !== 0) {
          decimals = payment.amount.split('.')[1].length
        }
        if (decimals > amounts[payment.currency].decimals) {
          amounts[payment.currency].decimals = decimals
        }
      }
      const totalAmounts = []
      for (const currency in amounts) {
        totalAmounts.push(`${amounts[currency].amount.toFixed(amounts[currency].decimals)} ${currency}`)
      }
      return totalAmounts.join(' + ')
    },
    async handleDataSharingConsent (data) {
      this.loading = false
      this.paymentInfos = data.response.payment_infos
      this.totalAmount = this.calculateTotalAmount(this.paymentInfos)
      this.paymentsCount = this.paymentInfos.length
      this.appName = data.response.app_name
      this.appDescription = data.response.app_description
    },
    async submitDataSharingConsent () {
      this.loading = true
      this.status = ''
      try {
        await BaseService.confirmPisDataSharingConsent()
      } catch (e) {
        console.error(e)
      }
      this.scheduleUpdate(500)
    },
    async handleAccountList (data) {
      this.loading = true
      if (this.widgetEnabled) {
        this.$emit('redirect-ais', data)
        return
      }
      this.redirect(data.response.redirect_url)
    },
    async handleAccountSelection (data) {
      this.loading = false
      this.accounts = data.response.accounts
    },
    async handlePaymentInitiation (data) {
      this.loading = true
      if (data.response.redirect_url) {
        // FIXME: The following logic looks generic and probably is to be spit in a separate function.
        const authInfo = data.response.auth_info[0]
        switch (authInfo.approach) {
          case 'REDIRECT':
            if (data.response.redirect_url) {
              await this.notifyLeaving()
              this.redirect(data.response.redirect_url)
              return
            }
            this.scheduleUpdate(1000)
            break
          case 'DECOUPLED':
            return await this.handleDecoupled(data)
        }
        return
      }
      try {
        const r = await BaseService.submitPaymentInitiation(this.$i18n.locale)
        if (r.data.response.status) {
          this.prevStatus = this.status
          this.status = r.data.response.status
          const handler = this.getHandler(this.status)
          handler(r.data)
          return
        }
        this.scheduleUpdate(1000)
      } catch (e) {
        console.error(e)
        this.error = e.message
      }
    },
    async handleDecoupled (data) {
      this.loading = false
      if (data.response.auth_data && data.response.auth_data.length) {
        this.authData = data.response.auth_data[0]
        if (['OTP_CODE'].includes(this.authData.type)) {
          return this.handleBlockingAuthData(data) // embedded flow with OTP Code
        }
        return this.handleAuthData(data) // decoupled flow
      }
      const redirectUri = data.response.redirect_url
      const authInfo = data.response.auth_info[0]
      let r
      if (redirectUri) { // handle authHint case, QR code generation flow
        this.displayOnly = null
        if (authInfo.info.auth_url_hints && authInfo.info.auth_url_hints.length === 1) {
          this.displayOnly = authInfo.info.auth_url_hints[0].auth_type
        }
        // check for bankid:/// will no longer work for some cases
        if (redirectUri.startsWith('bankid:///') || this.displayOnly) {
          this.bankIdAppUri = redirectUri
          try {
            r = await BaseService.confirmPayment({ locale: this.$i18n.locale })
          } catch (e) {
            throw new Error(
              this.$t('Unable to continue decoupled authorization')
            )
          }
        } else {
          this.message = this.$t('Waiting for completion of authentication')
          try {
            r = await BaseService.confirmPayment({ locale: this.$i18n.locale })
          } catch (e) {
            throw new Error(
              this.$t('Unable to continue decoupled authorization')
            )
          }
        }
      } else { //  handle no authData/authHint case (decoupled auth without message), for backward compatibility
        try {
          r = await BaseService.confirmPayment({ locale: this.$i18n.locale })
          console.log(r)
        } catch (e) {
          throw new Error(this.$t('Unable to continue decoupled authorization'))
        }
      }
      if (r.data.response.auth_data && r.data.response.auth_data.length) {
        this.authDataRequired = true
        this.authData = r.data.response.auth_data[0]
        this.otpCode = ''
        this.displayQrCodeIfNecessary(r.data)
        this.loading = false
        return
      }
      this.scheduleUpdate(1000, false)
    },
    handleBlockingAuthData (data) {
      this.authDataRequired = true
      this.otpCode = ''
      this.displayQrCodeIfNecessary(data)
      this.loading = false
    },
    async handleAuthData (data) {
      this.loading = false
      this.bankIdAppUri = data.response.redirect_url
      this.displayOnly = this.authData.type
      try {
        await BaseService.confirmPayment({ locale: this.$i18n.locale })
      } catch (e) {
        throw new Error(
          this.$t('Unable to continue decoupled authorization')
        )
      }
      this.scheduleUpdate(1000, false)
    },
    async notifyLeaving () {
      const r = await BaseService.notifyLeavingPis()
      if (r.data.response.message !== 'OK') {
        throw new Error(r.data.response.message)
      }
    },
    async handleRedirect (data) {
      if (data.response.redirect_url) {
        await this.notifyLeaving()
        this.redirect(data.response.redirect_url)
        return
      }
      const r = await BaseService.confirmPayment({ locale: this.$i18n.locale })
      if (r.data.response.redirect_url) {
        await this.notifyLeaving()
        this.redirect(r.data.response.redirect_url)
        return
      }
      this.scheduleUpdate(1000)
    },
    async submitOtpCode () {
      this.loading = true
      try {
        const r = await BaseService.confirmPayment({ locale: this.$i18n.locale, otp_code: this.otpCode })
        console.log(r)
        this.otpCode = ''
        this.qrCodeUri = ''
        this.authDataRequired = false
      } catch (e) {
        console.error(e)
      }
      this.scheduleUpdate(500)
    },
    async confirmPayment (data) {
      this.status = data.response.status
      const authInfo = data.response.auth_info[0]
      switch (authInfo.approach) {
        case 'REDIRECT':
          return await this.handleRedirect(data)
        case 'DECOUPLED':
          return await this.handleDecoupled(data)
      }
    },
    async handleDone (data) {
      this.loading = true
      this.message = ''
      if (data.response.redirect_url) {
        return this.redirect(data.response.redirect_url)
      }
    },
    async cancelPayment () {
      window.stop()
      this.loading = true
      this.cancelDisabled = true
      this.error = ''
      this.status = ''
      try {
        const r = await BaseService.cancelPayment()
        this.redirect(r.data.response.redirect_url)
      } catch (e) {
        console.error(e)
        this.scheduleUpdate(500)
      }
    },
    async togglePaymentsDescription () {
      this.showPaymentDetails = !this.showPaymentDetails
    },
    async submitCredentials (data) {
      this.loading = true
      this.prevStatus = this.status
      this.status = '' // this is used to hide credentials input, because loading doesn't hide them
      this.error = ''
      try {
        const r = await BaseService.submitCredentials(
          data.authMethod,
          Object.fromEntries(
            data.authCredentials.map((c) => [c.name, c.value])
          ),
          this.$i18n.locale
        )
        // The response structure is the same for the get payment request.
        // In case the debtor account was not provided, the ACCOUNT_LIST status is returned
        // and the redirect_url field contains link to the AIS flow. This is why the response is
        // handled through the getHandler. The auth_data check is probably irrelevant here, but it's
        // kept here for consistency with the implementation of the updateStatus function.
        this.status = r.data.response.status
        if (r.data.response.auth_data && r.data.response.auth_data.length) {
          this.authDataRequired = true
          this.authData = r.data.response.auth_data[0]
          this.otpCode = ''
          this.displayQrCodeIfNecessary(r.data)
          this.loading = false
          return
        }
        if (this.status) {
          const handler = this.getHandler(this.status)
          handler(r.data)
          return
        }
        this.error = r.data.response.error
      } catch (e) {
        console.error(e)
        this.error = e.message
      }
    },
    async submitAccountSelection () {
      this.loading = true
      this.error = ''
      this.status = ''
      this.message = ''
      try {
        const r = await BaseService.submitAccountSelection(
          this.account,
          this.$i18n.locale
        )
        const data = r.data
        this.status = data.response.status
        if (this.isFinalStatus()) {
          return this.handleDone(data)
        }
        const authInfo = data.response.auth_info[0]
        switch (authInfo.approach) {
          case 'REDIRECT':
            if (data.response.redirect_url) {
              await this.notifyLeaving()
              this.redirect(data.response.redirect_url)
              return
            }
            this.scheduleUpdate(1000)
            break
          case 'DECOUPLED':
            return await this.handleDecoupled(data)
        }
      } catch (e) {
        console.error(e)
      }
    },
    stopUpdate () {
      window.stop()
      clearTimeout(this.timeoutId)
      this.timeoutId = 0
    },
    openBankIdApp (data) {
      this.stopUpdate()
      if (data.blank) {
        window.open(data.redirectUri, '_blank')
      } else {
        window.location.href = data.redirectUri
      }
    },
    async onBankIdResume () {
      this.bankIdAppUri = ''
      this.loading = true
      try {
        await BaseService.confirmPayment({ locale: this.$i18n.locale })
      } catch (e) {
        throw new Error(this.$t('Unable to continue decoupled authorization'))
      }
      this.scheduleUpdate(1000)
    }
  },
  mounted () {
    this.scheduleUpdate(0)
    BaseService.setScheduleUpdateFunction(this.scheduleUpdate)
  }
}
</script>

<style lang="scss" scoped>

.loading-content {
  padding-top: 20vh;
}

.qrcode-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qrcode-image {
  border: 1px solid #ccc;
  display: none;
  width: 228px;
  height: 228px;
}
</style>
