type Props = {
  onCancel: () => any;
};

export const ConsentDisplaySkeletonSv = ({ onCancel }: Props) => {
  return (
    <div className="tilisy-consent animate-pulse">
      <div className="tilisy-terms">
        <p className="text-justify">
          <span>
            Autentisering initieras av <strong>Again</strong>.
          </span>
          <span>&nbsp;</span>
          <span className="mr-1">
            Efter att du har slutfört identifieringsprocessen kommer{" "}
            <em>listan över dina betalkonton, kontosaldon och transaktioner</em>{" "}
            att vara tillgängliga för organisationen som påbörjat
            autentiseringsprocessen.
          </span>
          <span>&nbsp;</span>
          <span>
            Genom att trycka <span>"Fortsätt autentisering"</span> godkänner du{" "}
            <a
              href="https://tilisy-sandbox.enablebanking.com/terms?locale=SV"
              target="_blank"
            >
              villkoren för Enable Banking API service
            </a>
            .
          </span>
          <span>
            Tryck <span>"Avbryt"</span> om du inte går med på att dela dina
            betalningsuppgifter.
          </span>
        </p>
      </div>
      <div className="tilisy-confirmation">
        <div className="form-inline justify-content-center">
          <button className="btn btn-theme" disabled>
            Fortsätt autentisering
          </button>
        </div>
        <div className="text-center">
          <button className="btn btn-link" onClick={onCancel}>
            Avbryt
          </button>
        </div>
      </div>
      <div className="tilisy-about">
        <p>
          <strong>Om Again</strong>
        </p>
        <p className="text-justify">Again Technology</p>
        <div>
          <p>
            <strong>Om Enable Banking API</strong>
          </p>
          <p className="text-justify">
            <span>
              Enable Banking API gör det möjligt att använda uppgifterna för
              ditt betalningskonto hos en bank eller annan leverantör av
              betalningstjänster (Account Servicing Payment Service Provider,
              ASPSP) i andra digitala tjänster.
            </span>
            <span>
              Användningen av dina betalningskontouppgifter förutsätter att du
              identifierar dig i den tjänsten i fråga.
            </span>
          </p>
          <p className="text-justify">
            <span>
              Enable Banking API upprätthåller ditt explicita samtycke för
              användningen av dina betalningskontouppgifter.
            </span>
            <span>
              Vi registrerar endast de uppgifter som behövs för identifiering.
            </span>
            <span>
              Enable Banking API kan ge andra digitala tjänster tillgång till
              dina uppgifter efter att du har godkänt dem för detta i en tjänst
              som din bank eller annan betalningstjänstleverantör
              tillhandahåller.
            </span>
            <span>
              Uppgifter om ditt betalningskonto överförs via Enable Banking API,
              men tjänsten varken sparar eller registrerar dessa uppgifter på
              något sätt.
            </span>
          </p>
          <p className="text-justify">
            <span>
              Enable Banking API ägs och förvaltas av Enable Banking Ab,
              (Företaget, Tjänsteleverantören), fo-nummer 2988499-7, Teknikvägen
              2 F 309, 02150 Esbo, Finland.
            </span>
            <span>
              Företaget har registrerats som en leverantör av
              kontoinformationstjänster (Account Information Service Provider,
              AISP) och övervakas av Finansinspektionen i Finland.
            </span>
            <span>
              Uppgifterna om registreringen kan kontrolleras på
              <a
                href="https://www.finanssivalvonta.fi/en/registers/supervised-entities/"
                target="_blank"
              >
                Finansinspektionen webbplats
              </a>
              .
            </span>
          </p>
          <p className="text-justify">
            <span>
              Vi rekommenderar att du bekantar dig med våra
              <a href="https://tilisy.enablebanking.com/terms" target="_blank">
                villkoren för Enable Banking API service
              </a>
              innan du identifierar dig i den tjänsten som din bank eller annan
              betalningstjänstleverantör tillhandahåller.
            </span>
            <span>
              Användningsvillkoren och annan information om tjänsten finns
              alltid tillgänglig på
              <a href="https://tilisy.enablebanking.com/terms" target="_blank">
                https://tilisy.enablebanking.com/terms
              </a>
              .
            </span>
            <span>
              Information om eventuella uppdateringar till tjänsten - samt om
              sådant missbruk eller annat otillbörligt bruk av den som kan
              äventyra dess datasäkerhet - ges via programvaran som levereras
              tillsammans med Enable Banking Oy.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
